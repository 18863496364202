import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SimulatorError } from "./handler";

export interface SimulatorState {
  isReady: boolean;
  isRunning: boolean;
  error?: SimulatorError;
}

const init: SimulatorState = {
  isReady: false,
  isRunning: false,
};

const simulatorStore = createSlice({
  name: "simulator",
  initialState: init,
  reducers: {
    error: (state, action: PayloadAction<SimulatorError>) => {
      state.error = action.payload;
      state.isRunning = false;
    },
    ready: (state) => {
      state.isReady = true;
    },
    notReady: (state) => {
      state.isReady = false;
    },
    running: (state) => {
      state.isRunning = true;
    },
    complete: (state) => {
      state.isRunning = false;
    },
  },
});

export const SimulatorActions = simulatorStore.actions;
export const SimulatorReducer = simulatorStore.reducer;
