import { DanikaResult } from "components/atoms/DanikaMetrics/types";
import { get } from "lodash";

export function convertDanikaResult(result: DanikaResult): DanikaResult {
  return {
    ...result,
    ann_y_eld: get(result, "annYeld", result.ann_y_eld),
    y_eld: get(result, "yeld", result.y_eld),
    downside_risk: get(result, "downsideRisk", result.downside_risk),
    max_drawdown: get(result, "maxDrawdown", result.max_drawdown),
    recovery_period: get(result, "recoveryPeriod", result.recovery_period) ?? 0,
    var95_1Y: get(result, "var95", result.var95_1Y),
    var99_1Y: get(result, "var99", result.var99_1Y),
  };
}
