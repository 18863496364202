import { config, STAGE_ENDPOINT } from "config";
import {
  GoalRequest,
  GoalResponse,
  MultipleGoalRequest,
  MultipleGoalResponse,
} from "../types";

export interface GoalSeekController {
  multiple(request: MultipleGoalRequest): Promise<MultipleGoalResponse>;
  single(request: GoalRequest): Promise<GoalResponse>;
}

const PROFILING_GOAL_SEEK = false;

function processReq(req: GoalRequest): GoalRequest {
  const notEmptyIndexes = findIndexes(req.money, (d) => d != 0);
  const money = notEmptyIndexes.map((d) => req.money[d]);
  const investmentDate = notEmptyIndexes.map((d) => req.investmentDate[d]);
  return {
    ...req,
    money,
    investmentDate,
  };
}

class concreteGoalSeekController implements GoalSeekController {
  private endpoint: string;
  private constructor() {
    let url = config().URL + "/goal";
    if (config().STAGE === "localhost") {
      url = STAGE_ENDPOINT.replace("https", "http") + "/goal";
    }
    this.endpoint = url;
  }
  async multiple(request: MultipleGoalRequest): Promise<MultipleGoalResponse> {
    let profilingStart = Date.now();

    let requests = request.requests.map((val) => processReq(val));

    const response = await fetch(this.endpoint + "/multiple", {
      body: JSON.stringify({ requests }),
      method: "POST",
    });
    if (response.status !== 200) {
      let data = await response.text();
      throw new Error(`GoalSeek error multiple: ${data}`);
    }

    let data: MultipleGoalResponse = await response.json();
    if (PROFILING_GOAL_SEEK) {
      console.log(`GoalSeek multiple ${Date.now() - profilingStart}ms `);
    }

    return data;
  }
  async single(request: GoalRequest): Promise<GoalResponse> {
    let profilingStart = Date.now();
    const response = await fetch(this.endpoint + "/single", {
      body: JSON.stringify(processReq(request)),
      method: "POST",
    });
    if (response.status !== 200) {
      let data = await response.text();
      throw new Error(`GoalSeek error single: ${data}`);
    }
    let data: GoalResponse = await response.json();
    if (PROFILING_GOAL_SEEK) {
      console.log(`GoalSeek single ${Date.now() - profilingStart}ms `);
    }
    return data;
  }

  private static instance: GoalSeekController;
  static getIstance(): GoalSeekController {
    if (!concreteGoalSeekController.instance) {
      concreteGoalSeekController.instance = new concreteGoalSeekController();
    }
    return concreteGoalSeekController.instance;
  }
}

export const goalSeek: GoalSeekController =
  concreteGoalSeekController.getIstance();

function findIndexes<T>(arr: T[], fn: (val: T) => boolean) {
  var indexes = [],
    i;
  for (i = 0; i < arr.length; i++) {
    if (fn(arr[i])) {
      indexes.push(i);
    }
  }
  return indexes;
}
