export interface OfflineStorage {
  set(key: string, obj: string): void;
  get(key: string): string | null;
}

class _storage implements OfflineStorage {
  set(key: string, obj: string): void {
    localStorage.setItem(key, obj);
  }
  get(key: string) {
    return localStorage.getItem(key);
  }
}

export const newLocalStorage = (): OfflineStorage => {
  return new _storage();
};
