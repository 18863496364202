import { EarnTable } from "contorller/simulations/types";
import { yearlyYield } from "contorller/utils";
import { DateTime } from "luxon";
import Matrix from "ml-matrix";
import { useMemo } from "react";
import { AMOUNT, DATES, INVESTED } from "simulator/types";
import { checkForNaN } from "utils/number";

export function getLastAmount(d: Matrix, amountColumn = AMOUNT): number {
  return d.get(amountColumn, d.columns - 1);
}

export function getLastInvestedAmount(
  d: Matrix,
  investedColumn: number = INVESTED
): number {
  return d.get(investedColumn, d.columns - 1);
}

export function getAbsoluteYield(
  d: Matrix,
  investedColumn: number = INVESTED,
  amountColumn: number = AMOUNT
): number {
  return (
    getLastAmount(d, amountColumn) / getLastInvestedAmount(d, investedColumn) -
    1
  );
}
export function getYearlyYield(
  d: Matrix,
  investedColumn: number = INVESTED,
  amountColumn: number = AMOUNT
): number {
  const to = DateTime.fromSeconds(d.get(DATES, d.columns - 1));
  const from = DateTime.fromSeconds(d.get(DATES, 0));
  const days = Math.ceil(to.diff(from, "days").days);
  if (days === 365) {
    return getAbsoluteYield(d, investedColumn, amountColumn);
  }
  return yearlyYield(
    getLastAmount(d, amountColumn),
    getLastInvestedAmount(d, investedColumn),
    days
  );
}

export function useEarnTable(rollingData: Matrix[]): EarnTable | null {
  return useMemo(() => {
    if (rollingData.length > 0) {
      const d = rollingData[0];
      const to = DateTime.fromSeconds(d.get(DATES, d.columns - 1));
      const from = DateTime.fromSeconds(d.get(DATES, 0));
      const days = Math.ceil(to.diff(from, "days").days);

      const absolutes = rollingData.map((v) => getAbsoluteYield(v));
      const yearly =
        days === 365 ? absolutes : rollingData.map((v) => getYearlyYield(v));
      const absoluteAvg =
        absolutes.reduce((p, n) => p + n, 0) / absolutes.length;

      const yearlyAvg =
        days === 365 ? absoluteAvg : yearlyYield(1 + absoluteAvg, 1, days - 1);

      return {
        absolute: {
          min: checkForNaN(Math.min(...absolutes)),
          max: checkForNaN(Math.max(...absolutes)),
          avg: checkForNaN(absoluteAvg),
        },
        annual: {
          min: checkForNaN(Math.min(...yearly)),
          max: checkForNaN(Math.max(...yearly)),
          avg: checkForNaN(yearlyAvg),
        },
      };
    } else {
      return null;
    }
  }, [rollingData]);
}
