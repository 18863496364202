import { plansByKey } from "components/organismes/planPicker";
import { useIsLoggedIn } from "contorller/auth/controller";
import { PaymentMethods, UserBillingPlan } from "contorller/auth/types";
import { useEmpirichQuery } from "contorller/utils";

export function usePaymentMethods() {
  const isLoggedIn = useIsLoggedIn();
  const query = useEmpirichQuery<PaymentMethods>(
    {
      endpoint: "/v1/payment_method/",
    },
    ["myPaymentMethods"],
    {
      enabled: isLoggedIn,
    }
  );
  return {
    query,
  };
}

export function getBillingPlanName(plan: UserBillingPlan): string {
  switch (plan) {
    case UserBillingPlan.PREMIUM:
      return plansByKey.m_premium.name;
    case UserBillingPlan.PREMIUM_PLUS:
      return plansByKey.m_premium_plus.name;
    case UserBillingPlan.PREMIUM_TEST:
      return plansByKey.m_premium_test.name;
    default:
      return plansByKey.m_base.name;
  }
}
