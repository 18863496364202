import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { orderBy, pullAt } from "lodash";
import { Investment, MESRequest } from "../types";

export interface MESState {
  request?: MESRequest;
  error?: JsonResponseError;
  investmentsEnabled: boolean;
  investementAmount: number;
  investments: Investment[];
  loading: boolean;
}

const initialState: MESState = {
  loading: false,
  investmentsEnabled: false,
  investementAmount: 0,
  investments: [],
};

const mesStore = createSlice({
  name: "mes",
  reducers: {
    startSimulation: (
      state,
      action: PayloadAction<{ request: MESRequest }>
    ) => {
      state.loading = true;
      state.investments = [];
      state.investmentsEnabled = false;
      state.request = action.payload.request;
      state.investementAmount = state.request.initialInvestment;
    },
    reset: (state) => initialState,
    error: (state, action: PayloadAction<{ error: JsonResponseError }>) => {
      state.error = action.payload.error;
    },
    endSimulation: (state) => {
      state.loading = false;
    },
    toggleInvestementsEnabled: (state) => {
      state.investmentsEnabled = !state.investmentsEnabled;
      state.investments = [];
    },
    changeInvestmentAmount: (
      state,
      action: PayloadAction<{ amount: number }>
    ) => {
      state.investementAmount = action.payload.amount;
    },
    addInvestment: (
      state,
      action: PayloadAction<{ investement: Investment }>
    ) => {
      state.investments.push(action.payload.investement);
      state.investments = orderBy(state.investments, "unix");
    },
    resetInvestments: (state) => {
      state.investments = [];
    },
    removeInvestmentAtIndex: (
      state,
      action: PayloadAction<{ index: number }>
    ) => {
      pullAt(state.investments, action.payload.index);
    },
  },
  initialState,
});

export const MESActions = mesStore.actions;
export const MESReducer = mesStore.reducer;
