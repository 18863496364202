import { getFund } from "components/atoms/fundName";
import { config } from "config";
import { store } from "contorller";
import { authController } from "contorller/auth/controller";
import { Auther } from "contorller/auth/types";
import { Fund } from "contorller/fund/redux";
import { MESRequest } from "../types";
import { MESActions } from "./redux";

export interface MESValue {
  closes: number[];
  unix: number;
}
export interface MESResponse {
  request: MESRequest;
  values: MESValue[];
  totalReturn: MESValue[];
  funds: Fund[];
}

export const mesController = (): MESController =>
  concreteMESController.getInstance(authController().getAuther());

export interface MESController {
  run(requst: MESRequest): Promise<MESResponse | null>;
  getResults(): MESResponse | null;
  clearAllResults(): void;
}

export class concreteMESController implements MESController {
  protected auther: Auther;
  private static _instance: concreteMESController;
  private results: MESResponse | null;

  public async run(request: MESRequest): Promise<MESResponse | null> {
    store.dispatch(MESActions.startSimulation({ request }));
    try {
      const [totalResponse, response] = await Promise.all([
        this.runRequest(request, true),
        this.runRequest(request, false),
      ]);
      if (totalResponse == null || response == null) {
        throw new Error("Missing response");
      }

      const funds = await Promise.all(request.funds.map((f) => getFund(f.id)));
      const data: MESResponse = {
        request,
        values: response,
        totalReturn: totalResponse,
        funds,
      };

      this.results = data;
      store.dispatch(MESActions.endSimulation());
      return data;
    } catch (err: any) {
      store.dispatch(MESActions.error({ error: err.message }));
    }

    return null;
  }

  public getResults() {
    return this.results;
  }

  public clearAllResults() {
    this.results = null;
    store.dispatch(MESActions.reset());
  }

  private async runRequest(
    req: MESRequest,
    shouldShowTotalReturn: boolean
  ): Promise<MESValue[] | null> {
    const response = await fetch(
      config().URL +
        (shouldShowTotalReturn
          ? "/history/aggregate"
          : "/nr-history/aggregate"),
      {
        method: "POST",
        body: JSON.stringify({
          ids: req.funds.map((f) => f.id),
          start: req.start,
          end: req.end,
          days: req.days,
        }),
        headers: await this.auther.getHeaderToken(),
      }
    );
    if (response.status === 200) {
      const data: Pick<MESResponse, "values"> = await response.json();
      return data.values;
    } else {
      throw response.body;
    }
  }

  constructor(auther: Auther) {
    this.auther = auther;
    this.results = null;
  }

  public static getInstance(auther: Auther): concreteMESController {
    if (!concreteMESController._instance) {
      concreteMESController._instance = new concreteMESController(auther);
    }
    return concreteMESController._instance;
  }
}
