import { Colors } from "@blueprintjs/core";
import { usePrinting } from "components/context/PrintContext";
import { getColorPaletteAtIndex } from "config";
import { Sum } from "contorller";
import { calcPic } from "contorller/simulations/PIC/fx";
import {
  DataStepIn,
  DataStepOut,
  FundWeight,
  IISResponse,
  Simulation,
  Snap,
} from "contorller/simulations/types";
import { TFunction } from "i18next";
import { compact, flatten, isEmpty, times } from "lodash";
import { DateTime } from "luxon";
import type { Annotations, PlotData } from "plotly.js";
import { createSimulationHistograms, getColorFn } from "./utils";

const numberFomatter = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR",
});

export interface IISGraphData {
  histogramTitle: string;
  lineTitle: string;
  istogramAnnotations: Partial<Annotations>[];
  istogram: Partial<PlotData>[];
  stepRange: { range: [Date, Date] };
  pic: Partial<PlotData>;
  actionary: Partial<PlotData>;
  value: Partial<PlotData>;
  values: Partial<PlotData>[];
  closes: Partial<PlotData>[];
  stepin: Partial<PlotData>;
  stepout: Partial<PlotData>;
  dataStepIn: DataStepIn[][];
  dataStepOut: DataStepOut[][];
  monetary: Partial<PlotData>;
}

export const getStocksValue = (sim: Simulation): number[] => {
  return sim.snaps.map<number>((v) => Sum(v.funds.slice(1, v.funds.length)));
};
export const getIisValues = (sim: Simulation): number[] => {
  return sim.snaps.map<number>((v) => {
    return Sum(v.funds) + (v.moneyExchange || 0);
  });
};

export const getMonetaryValues = (sim: Simulation): number[] => {
  return sim.snaps.map((e) => e.funds[0]);
};

interface SimulationOptions {
  showSingleFund: boolean;
  showMeanPerfomance: boolean;
}

export function iisGraph(
  index: number,
  response: IISResponse,
  funds: FundWeight[],
  t: TFunction,
  options?: SimulationOptions
): IISGraphData {
  const simulation = response.simulations[index];
  const dates = simulation.snaps.map<Date>((v) => new Date(v.unix * 1000));
  const stepInDate = simulation.snaps.filter(
    (v) => v.stepIn !== null && (v.stepIn || []).length > 0
  );
  const stepOutDate = simulation.snaps.filter(
    (v) => v.stepOut !== null && (v.stepOut || []).length > 0
  );
  let initialAmount = Sum(response.simulations[index].snaps[0].funds);
  const picSimulations = response.simulations.map((sim, index) =>
    calcPic(
      {
        funds: funds,
        name: t("PIC"),
        investments: [],
        initialInvestment: initialAmount,
        rebalance: 0,
        start: sim.snaps[0].unix,
        end: 0,
      },
      sim.snaps.map((v) => {
        return {
          closes: v.closes.slice(1, v.closes.length),
          unix: v.unix,
        };
      })
    )
  );

  const simValue = simulation.absolute;
  const invested = response.request?.totalInvestment ?? 0;
  const pic = picSimulations[index];

  const picValues = pic.snaps.map<number>((v) => Sum(v.funds));
  const monetaryValues = getMonetaryValues(simulation);
  const iisValues = getIisValues(simulation);
  const stockValues = getStocksValue(simulation);

  const utilityIndex = picSimulations.map((sim, index) => {
    const lastPicClose = Sum(sim.snaps[sim.snaps.length - 1].funds);
    const smallestPicAmount = Math.min(...sim.snaps.map((d) => Sum(d.funds)));
    const iisSimValues = getIisValues(response.simulations[index]);

    const lastIISClose = iisSimValues[iisSimValues.length - 1];
    const smallestIISAmount = Math.min(...iisSimValues);
    const c = 1 - smallestPicAmount / initialAmount;
    const d = 1 - lastPicClose / initialAmount;
    const a = 1 - smallestIISAmount / initialAmount;
    const b = 1 - lastIISClose / initialAmount;
    // console.log(
    //   new Date(sim.request.start * 1000),
    //   a,
    //   b,
    //   c,
    //   d,
    //   a - c + (b - d)
    // );

    return a - c + (b - d);
  });

  const worstStartDate =
    picSimulations[indexOfBiggest(utilityIndex)].request.start;
  const bestStartDate =
    picSimulations[indexOfSmallest(utilityIndex)].request.start;
  const dateOptions = { day: "numeric", month: "short", year: "numeric" };
  const positive = getColorFn("positive");
  const negative = getColorFn("negative");
  const stepInIndexes = simulation.snaps
    .map((v, index) => {
      if (v.stepIn != null) {
        return index;
      }
      return null;
    })
    .filter((f) => f != null) as number[];
  const stepInDates = stepInIndexes.map((index) => dates[index]);
  const stepOuts = compact(
    simulation.snaps.map((v) =>
      v.stepOut != null ? { data: v.stepOut, unix: v.unix } : null
    )
  );
  const printingCtx = usePrinting();

  const stepOutDates = times(response.request?.durationIIS ?? 0).map(
    (_, index) =>
      DateTime.fromJSDate(dates[0]).plus({ months: index }).toJSDate()
  );
  const stepOutValues = stepOutDates.map((value, index, stepOutDates) => {
    const from = value.valueOf() / 1000;
    const to =
      (stepOutDates[index + 1] ?? dates[dates.length - 1]).valueOf() / 1000;
    return flatten(
      stepOuts.filter((f) => f.unix >= from && f.unix <= to).map((d) => d.data)
    );
  });
  return {
    monetary: {
      type: "scatter",
      visible: "legendonly",
      name: t("Monetari"),
      marker: {
        color: Colors.ORANGE4,
      },
      x: dates,
      hoverinfo: "x+text",
      hovertext: monetaryValues.map(numberFomatter.format),
      y: monetaryValues,
    },
    istogramAnnotations: [
      {
        y: 0,
        x: new Date(worstStartDate * 1000) as any,
        xref: "x",
        align: "center",
        ax: 0,
        yshift: -8,
        yref: "y",
        text: ".",
        xanchor: "center",
        yanchor: "bottom",
        font: {
          size: 30,
          color: "orange",
        },
        opacity: 0.85,
        showarrow: false,
      },
      {
        y: 0,
        x: new Date(bestStartDate * 1000) as any,
        xref: "x",
        ax: 0,
        yref: "y",
        text: ".",
        yshift: -8,
        xanchor: "center",
        yanchor: "bottom",
        font: {
          size: 30,
          color: "green",
        },
        align: "center",
        showarrow: false,
      },
    ],
    istogram: createSimulationHistograms(
      response.simulations.map((r) => ({
        absolute: r.absolute,
        start: new Date(r.snaps[0].unix * 1000),
        end: new Date(r.snaps[r.snaps.length - 1].unix * 1000),
      })),
      {
        positive,
        negative,
      },
      t,
      {
        selectedHistogramIndex: index,
        showMeanPerfomance: options?.showMeanPerfomance,
        isPrinting: printingCtx.isPrinting,
      }
    ),
    histogramTitle: t("Casi Osservati:") + ` ${response.simulations.length}`,
    lineTitle: buildSimulationTitle(
      pic.snaps[0].unix,
      pic.snaps[pic.snaps.length - 1].unix,
      simulation.absolute,
      simulation.annual,
      t
    ),
    pic: {
      type: "scatter",
      x: pic.snaps.map<Date>((v) => new Date(v.unix * 1000)),
      y: picValues,
      name: t("PIC"),
      hoverinfo: "x+text",
      hoverlabel: {
        font: {
          color: "white",
        },
      },
      hovertext: picValues.map(
        (v) =>
          t("Risultato") +
          ` ${numberFomatter.format(v)} (${(
            100 *
            ((v - invested) / invested)
          ).toFixed(2)}%)`
      ),
      visible: "legendonly",
      marker: {
        color: Colors.VIOLET4,
      },
    },
    actionary: {
      type: "scatter",
      x: dates,
      y: stockValues,
      hoverinfo: "x+text",
      hovertext: stockValues.map(numberFomatter.format),
      hoverlabel: {
        font: {
          color: "white",
        },
      },
      name: t("Azionari"),
      visible: "legendonly",
      marker: {
        color: Colors.GREEN3,
      },
    },
    value: {
      type: "scatter",
      x: dates,
      y: iisValues,
      name: t("IIS"),
      hoverinfo: "x+text",
      hoverlabel: {
        font: {
          color: "white",
        },
      },
      hovertext: simulation.snaps.map((v, index) => {
        const val = iisValues[index];
        const earn = val - invested;
        const percentage = 100 * (earn / invested);
        return (
          t("Guadagno") +
          ` ${numberFomatter.format(earn)} + ` +
          t("Investito") +
          ` ${numberFomatter.format(invested)} = ` +
          t("Risultato") +
          ` ${numberFomatter.format(val)} (${percentage.toFixed(2)}%)`
        );
      }),
      marker: {
        color: Colors.BLUE3,
      },
    },
    values:
      options?.showSingleFund === true
        ? simulation.snaps[0].closes.map<Partial<PlotData>>((_, index) => {
            return {
              type: "scatter",
              x: dates,
              y: simulation.snaps.map<number>((v) => v.funds[index]),
              name: index === 0 ? "Monetario" : funds[index - 1].name,
            };
          })
        : [],
    closes: simulation.snaps[0].closes.map<Partial<PlotData>>((_, index) => {
      return {
        type: "scatter",
        x: dates,
        y: simulation.snaps.map<number>((v) => v.closes[index]),
        name: index === 0 ? "Monetario" : funds[index - 1].name,
      };
    }),
    stepRange: {
      range: [dates[0], dates[dates.length - 1]],
    },
    stepin: {
      name: t("Step-in"),
      type: "bar",
      x: stepInDates,
      hoverinfo: "x+text",
      marker: {
        color: getColorPaletteAtIndex(2),
      },
      y: stepInIndexes.map((index) => {
        const v = simulation.snaps[index];
        if (v.stepIn != null) {
          return Sum((v.stepIn || []).map<number>((j) => j.money));
        } else {
          return null;
        }
      }),
      text: stepInIndexes.map((index) => {
        const v = simulation.snaps[index];
        if (v.stepIn != null) {
          return StepInHover({ snap: v, funds });
        } else {
          return "";
        }
      }),
    },
    stepout: {
      name: t("Step-out"),
      type: "bar",
      hoverinfo: "x+text",

      x: stepOutDates,
      marker: {
        color: getColorPaletteAtIndex(1),
      },

      y: stepOutValues.map((d) => Sum(d.map((t) => t.earn))),
      text: stepOutValues.map<string>((stepOut) => {
        if (!isEmpty(stepOut)) {
          return StepOutHover({ stepOut, funds });
        } else {
          return "";
        }
      }),
    },
    dataStepIn: stepInDate.map<DataStepIn[]>((v) => {
      if (v.stepIn) {
        return v.stepIn;
      }
      return [
        {
          id: "",
          multiple: 0,
          money: 0,
          close: 0,
          vavg: 0,
        },
      ];
    }),
    dataStepOut: stepOutDate.map<DataStepOut[]>((v) => {
      if (v.stepOut) return v.stepOut;

      return [
        {
          earn: 0,
          id: "",
          close: 0,
          vavg: 0,
        },
      ];
    }),
  };
}

export function StepInHover(props: { snap: Snap; funds: FundWeight[] }) {
  const stepIn = props.snap.stepIn ?? [];

  const getFund = (s: DataStepIn) =>
    props.funds.find((f) => f.id === s.id)?.name;
  return stepIn
    .map(
      (s) => `<i>${getFund(s)}</i> <b>${numberFomatter.format(
        s.money
      )}</b> ( x${s.multiple} )<br>
    `
    )
    .join("");
}

export function StepOutHover(props: {
  stepOut: DataStepOut[];
  funds: FundWeight[];
}) {
  const stepOut = props.stepOut;

  const getFund = (s: DataStepOut) =>
    props.funds.find((f) => f.id === s.id)?.name;
  return stepOut
    .map(
      (s) => `<i>${getFund(s)}</i> <b>${numberFomatter.format(s.earn)}</b><br>`
    )
    .join("");
}

function indexOfBiggest(a: number[]): number {
  var biggest = 0;

  for (var i = 1; i < a.length; i++) {
    if (a[i] > a[biggest]) biggest = i;
  }

  return biggest;
}

function indexOfSmallest(a: number[]): number {
  var lowest = 0;

  for (var i = 1; i < a.length; i++) {
    if (a[i] < a[lowest]) lowest = i;
  }

  return lowest;
}

export function buildSimulationTitle(
  start: number,
  end: number,
  absolute: number,
  annual: number,
  t: TFunction
) {
  const positive = getColorFn("positive");
  const negative = getColorFn("negative");
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return (
    t("Simulazione del") +
    ` <span style="color: ${positive(true)}">${new Date(
      start * 1000
    ).toLocaleDateString("it", dateOptions)} - ${new Date(
      end * 1000
    ).toLocaleDateString("it", dateOptions)}</span>   ` +
    t("Assoluto:") +
    ` <span style="color: ${absolute > 0 ? positive(true) : negative(true)}">${(
      absolute * 100
    ).toFixed(2)}%</span>  ` +
    t("Annuale:") +
    ` <span style="color: ${annual > 0 ? positive(true) : negative(true)}">${(
      annual * 100
    ).toFixed(2)}%</span>`
  );
}
