import { config } from "config";
import { authController } from "contorller/auth/controller";
import { Auther } from "contorller/auth/types";
import { CodeResponse } from "contorller/portfolio/types";
import {
  PROTECTION_OBJ,
  PTF_OBJ,
  TPTF_OBJ,
  codeResponse,
  objResponse,
  timeShare,
  typedSharableObject
} from "./types";

export interface ShareController {
  acceptCode(code: string): Promise<typedSharableObject>;
  share(id: string, deadline: timeShare, objType: string): Promise<CodeResponse>;
  resolveCode(code: string): Promise<typedSharableObject>;
  revokeCode(code: string):void;
}


export const shareController = (): ShareController =>
  concreteClassShare.instance(authController().getAuther());

const url = config().URL + "/v1/share";

class concreteClassShare implements ShareController {
  private auther: Auther;

  private constructor(auther: Auther) {
    this.auther = auther;
  }

  async acceptCode(code: string): Promise<typedSharableObject> {
    const response = await fetch(url + "/accept/" + code, {
      headers: await this.auther.getHeaderToken(),
    });

    if (response.status === 200) {
      const data: objResponse = await response.json();
      if (data.code > 0 && data.hasError) {
        //store.dispatch(PortfolioActions.share_error({ error: data }));
        throw new Error(data.error); 
      }
      //store.dispatch(PortfolioActions.prependPortfolio({ portfolio: data }));
      switch(data.payload?.objType){
        case PTF_OBJ:
            return {type: PTF_OBJ, object:data.payload.value?.Ptf}
        case TPTF_OBJ:
            return {type: TPTF_OBJ, object:data.payload.value?.Tptf?.tPortfolio}
        case PROTECTION_OBJ:
            return {type: PROTECTION_OBJ, object:data.payload.value?.Protection}
        default:
            throw new Error("Invalid response type");
      }
    }

    //store.dispatch(PortfolioActions.share_error({ error: data }));
    const data: string = await response.text();
    throw new Error(data);
  }

  async share(id: string, deadline: timeShare, objType: string): Promise<CodeResponse> {
    let shareUrl: string = url
    switch (objType) {
        case PTF_OBJ:
            shareUrl += "/portfolio/"
            break;
        case TPTF_OBJ:
            shareUrl += "/tptf/"
            break;
        case PROTECTION_OBJ:
            shareUrl += "/protection/"
            break;
        default:
            throw new Error("Invalid object type");
    }
    const response = await fetch(shareUrl + id + "?time=" + deadline, {
      headers: await this.auther.getHeaderToken(),
    });

    if (response.status === 200) {
      const data: codeResponse = await response.json();
      if (data.code > 0 && data.hasError) {
        //store.dispatch(PortfolioActions.share_error({ error: data }));
        throw new Error(data.error); 
      }
      const cr : CodeResponse = {code:data.payload?.code}
      return cr;
    }
    const data: string = await response.text();
    //store.dispatch(PortfolioActions.share_error({ error: data }));
    throw new Error(data);
  }

  async resolveCode(code: string): Promise<typedSharableObject> {
    const response = await fetch(url + "/show/" + code, {
      headers: await this.auther.getHeaderToken(),
    });

    if (response.status === 200) {
      const data: objResponse = await response.json();
      if (data.code > 0 && data.hasError) {
        //store.dispatch(PortfolioActions.share_error({ error: data }));
        throw new Error(data.error); 
      }
      //store.dispatch(PortfolioActions.prependPortfolio({ portfolio: data }));
      let returnValue : typedSharableObject;
      switch(data.payload?.objType){
        case PTF_OBJ:
            return {type: PTF_OBJ, object:data.payload.value?.Ptf}
        case TPTF_OBJ:
            return {type: TPTF_OBJ, object:data.payload.value?.Tptf?.tPortfolio}
        case PROTECTION_OBJ:
            return {type: PROTECTION_OBJ, object:data.payload.value?.Protection}
        default:
            throw new Error("Invalid response type");
      }
    }
    //store.dispatch(PortfolioActions.share_error({ error: data }));
    const data: string = await response.text();
    throw new Error(data);
  }

  async revokeCode(code: string) {
    const response = await fetch(url + "/revoke/" + code, {
      headers: await this.auther.getHeaderToken(),
    });

    if (response.status === 200) {
      const data: objResponse = await response.json();
      if (data.code > 0 && data.hasError) {
        //store.dispatch(PortfolioActions.share_error({ error: data }));
        throw new Error(data.error); 
      }
      return
    }
    //store.dispatch(PortfolioActions.share_error({ error: data }));
    const data: string = await response.text();
    throw new Error(data);
  }

  private static _instance: concreteClassShare;

  static instance(auther: Auther): concreteClassShare {
    if (!concreteClassShare._instance) {
      concreteClassShare._instance = new concreteClassShare(auther);
    }
    return concreteClassShare._instance;
  }
}
