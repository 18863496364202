import { Sum } from "contorller";
import { absoluteEarge, annualEarge, getDays } from "contorller/utils";
import { PICRequest, PICResponse } from "../types";
import { calcPic, myFormatDate } from "./fx";

export interface InvestmentResult {
  annual: number;
  absolute: number;
  money: number;

  funds: number[];
  quotes: number[];
}
export interface InvestmentParams {
  start: Date;
  end: Date;
  money: number;
  picId: string;
  shouldFixQuotes?: boolean;
}
export function picInvestment(
  picResponse: PICResponse,
  params: InvestmentParams
): InvestmentResult {
  const shouldFixQuotes = params.shouldFixQuotes ?? false;
  let s = picResponse.snaps.map((e) => myFormatDate(new Date(e.unix * 1000)));
  let indexStartDay: number = s.indexOf(myFormatDate(params.start));
  let indexEndDay: number = s.indexOf(myFormatDate(params.end));
  if (indexEndDay === -1 || indexStartDay === -1) {
    //console.error(params)
    throw new Error(
      `ho un problmea nel trovare gli indci ${myFormatDate(
        params.start
      )} ${myFormatDate(params.end)} ${
        picResponse.name
      } ${indexStartDay} ${indexEndDay} `
    );
  }

  let startDay = picResponse.snaps[0];
  let invesmtentDay = picResponse.snaps[indexStartDay];
  let exitDay = picResponse.snaps[indexEndDay];

  let quoteDayStart = picResponse.request.funds.map(
    (e, i) => (e.weight * params.money) / invesmtentDay.closes[i]
  );

  let fundsDayStart = picResponse.request.funds.map(
    (e, i) => invesmtentDay.funds[i]
  );

  let fundsDaySum = fundsDayStart.reduce((p, v) => p + v, 0);
  // let vl = quoteDayStart.map<number>(
  //   (e, i) => exitDay.closes[i] * quoteDayStart[i]
  // );

  // console.log(
  //   "=>",
  //   shouldFixQuotes,
  //   quoteDayStart,
  //   absoluteEarge(Sum(vl), params.money)
  // );

  const request: PICRequest = {
    ...picResponse.request,
    funds: picResponse.request.funds.map((f, i) => ({
      ...f,
      weight: shouldFixQuotes ? fundsDayStart[i] / fundsDaySum : f.weight,
    })),
    initialInvestment: params.money,
  };

  const days = calcPic(
    request,
    picResponse.snaps.slice(indexStartDay, indexEndDay + 1),
    indexStartDay % picResponse.request.rebalance
  ).snaps;
  const vl = days[days.length - 1].funds;
  return {
    quotes: quoteDayStart,
    funds: vl,
    money: Sum(vl),
    annual: annualEarge(
      Sum(vl),
      params.money,
      getDays(params.start, params.end)
    ),
    absolute: absoluteEarge(Sum(vl), params.money),
  };
}
