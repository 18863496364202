import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SettingsState {
  opacity: number;
  expanded: boolean;
  showMeanPerfomance: boolean;
  shouldFixQuotes: boolean;
  logarithmic?: boolean;
  colorBlindness: boolean;
}

function loadInitialState(): SettingsState {
  const opacityItem = localStorage.getItem("settings_opacity");
  const showMeanPerfomanceItem = localStorage.getItem(
    "settings_showMeanPerfomance"
  );
  const logarithmicItem = localStorage.getItem(
    "settings_logarithmic"
  );
  const colorBlindnessItem = localStorage.getItem(
    "settings_colorBlindness"
  );
  return {
    opacity: opacityItem != null ? parseFloat(opacityItem) : 0.5,
    expanded: false,
    logarithmic: logarithmicItem === "true",
    showMeanPerfomance: showMeanPerfomanceItem === "true",
    colorBlindness: colorBlindnessItem === "true",
    shouldFixQuotes: false,
  };
}

const settingStore = createSlice({
  name: "settings",
  reducers: {
    toggleExpanded: (state) => {
      state.expanded = !state.expanded;
    },
    toggleLogarithmic: (state) => {
      state.logarithmic = !state.logarithmic;
      localStorage.setItem(
        "settings_logarithmic",
        state.logarithmic ? "true" : "false"
      );
    },
    toggleFixQuotes: (state) => {
      state.shouldFixQuotes = !state.shouldFixQuotes;
    },
    toggleMeanPerformance: (state) => {
      state.showMeanPerfomance = !state.showMeanPerfomance;
      localStorage.setItem(
        "settings_showMeanPerfomance",
        state.showMeanPerfomance ? "true" : "false"
      );
    },
    toggleColorBlindness: (state) => {
      state.colorBlindness = !state.colorBlindness;
      localStorage.setItem(
        "settings_colorBlindness",
        state.colorBlindness ? "true" : "false"
      );
    },
    setOpacity: (state, action: PayloadAction<{ opacity: number }>) => {
      state.opacity = action.payload.opacity;
      localStorage.setItem("settings_opacity", state.opacity.toFixed(2));
    },
  },
  initialState: loadInitialState(),
});

export const SettingsAction = settingStore.actions;
export const SettingsReducer = settingStore.reducer;
