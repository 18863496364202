import { FundHistory } from "contorller/history/db";
import { DateTime } from "luxon";

export function scaleFundData(
  data: FundHistory,
  start: DateTime,
  end: DateTime
): FundHistory {
  let startDate = DateTime.fromSeconds(data.startDate).startOf("day");
  let endDate = DateTime.fromSeconds(data.endDate).startOf("day");
  // if (startDate > start) {
  //   throw new Error(
  //     `Cannot scale fund data with start date ${start.toLocaleString()} out of bounds of ${startDate.toLocaleString()}`
  //   );
  // }
  // if (endDate < end) {
  //   throw new Error(
  //     `Cannot scale fund data with end date ${end.toLocaleString(
  //       DateTime.DATETIME_FULL
  //     )} out of bounds of ${endDate.toLocaleString(DateTime.DATETIME_FULL)}`
  //   );
  // }

  const startIndex = Math.ceil(start.diff(startDate, "days").days);
  const endIndex = Math.ceil(end.diff(startDate, "days").days);

  // console.log(
  //   `[${data.fundId.split("-")[0]}] ${DateTime.fromSeconds(
  //     data.startDate
  //   ).toLocaleString()} | ${start.toLocaleString()} | ${startIndex} -> ${DateTime.fromSeconds(
  //     data.endDate
  //   ).toLocaleString()} | ${end.toLocaleString()} | ${endIndex} | ${
  //     data.values.slice(startIndex, endIndex).length
  //   }`
  // );
  return {
    ...data,
    values: data.values.slice(startIndex, endIndex),
    startDate: start.toSeconds(),
    endDate: end.toSeconds(),
  };
}
