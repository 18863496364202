import { decode, decodeAsync } from "@msgpack/msgpack";
import { Sum } from "contorller";
import { GoalRequest, MultipleGoalRequest, PACResponse } from "./types";

/** Trasforma una singola simulazione PAC senza spot operation in un unica richiesta goalSeek che ne permette di calcolare l'annuale */
export function pacToGoalSeekRequest(pac: PACResponse): GoalRequest {
  let lastDay = pac.snaps[pac.snaps.length - 1];
  return {
    money: [
      pac.request.initialInvestment,
      ...pac.snaps.slice(1).map((e) => pac.request.rate),
    ],
    exit: Sum(lastDay.funds),
    investmentDate: [
      pac.snaps[0].unix,
      ...pac.snaps.slice(1).map((e) => e.unix),
    ],
    endInvestment: lastDay.unix,
  };
}
/** Trasforma una pac con simulazioni spot in un array di richiesta goalSeek per il servizio /multiple */
export function pacWithSpoToGoalSeekRequests(
  pac: PACResponse
): MultipleGoalRequest {
  const requests: MultipleGoalRequest = {
    requests: pac.simulationsWithSpots.map((r, index, total) => ({
      endInvestment: r.days[r.days.length - 1].unix,
      exit: Sum(r.days[r.days.length - 1].funds),
      investmentDate: r.days.map((e) => e.unix),
      money: r.days.map((e) => Sum(e.rate)),
    })),
  };

  return requests;
}

// https://github.com/msgpack/msgpack-javascript#decoding-a-blob
export async function decodeFromBlob<T>(blob: Blob): Promise<T> {
  if (blob.stream) {
    // Blob#stream(): ReadableStream<Uint8Array> (recommended)
    return (await decodeAsync(blob.stream() as any)) as T;
  } else if (blob.arrayBuffer) {
    // Blob#arrayBuffer(): Promise<ArrayBuffer> (if stream() is not available)
    return decode(await blob.arrayBuffer()) as T;
  } else {
    // Fix to enable polyfil.
    return decode(await arrayBufferPolyfill(blob)) as T;
  }
}

function arrayBufferPolyfill(blob: Blob): Promise<ArrayBuffer> {
  // this: File or Blob
  return new Promise((resolve) => {
    let fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result as ArrayBuffer);
    };
    fr.readAsArrayBuffer(blob);
  });
}
