import Matrix from "ml-matrix";
import { AMOUNT, INVESTED, INVESTED_FIXED_RATE } from "simulator/types";
import { RealPicOverview } from "../pic";
import { getAbsoluteYield, getYearlyYield } from "./earnTable";

export function calculatePicOverview(
  shouldDeflate: boolean,
  windowData: Matrix | null
): RealPicOverview | null {
  if (windowData != null) {
    const last = windowData.columns - 1;
    return {
      shouldDeflate,
      invested: {
        amount: windowData.get(AMOUNT, last),
        yearly: getYearlyYield(windowData),
        absolute: getAbsoluteYield(windowData),
      },
      fixedRate: {
        amount: windowData.get(INVESTED_FIXED_RATE, last),
        yearly: getYearlyYield(windowData, INVESTED, INVESTED_FIXED_RATE),
        absolute: getAbsoluteYield(windowData, INVESTED, INVESTED_FIXED_RATE),
      },
    };
  }
  return null;
}
