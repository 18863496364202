import { configureStore } from "@reduxjs/toolkit";
import { isArray } from "lodash";
import { adminReducer } from "./admin/redux";
import { authReducer } from "./auth/reducer";
import { ConfrontableReducer } from "./confrontable/redux";
import { FundsReducer } from "./fund/redux";
import { PortfolioReducer } from "./portfolio/redux";
import { SettingsReducer } from "./settings/redux";
import { DCReducer } from "./simulations/DC/redux";
import { IISReducer } from "./simulations/IIS/redux";
import { MESReducer } from "./simulations/MES/redux";
import { PACReducer } from "./simulations/PAC/redux";
import { PICReducer } from "./simulations/PIC/redux";
import { PICDReducer } from "./simulations/PICD/redux";
import { SimulationReducer } from "./simulations/redux";
import { SimulatorReducer } from "./simulations/simulator/redux";

export interface JsonResponseError {
  code: string;
  error: string;
  status: string;
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    portfolio: PortfolioReducer,
    simulator: SimulatorReducer,
    //TODO: si può togliere?
    simulation: SimulationReducer,
    admin: adminReducer,
    pic: PICReducer,
    picd: PICDReducer,
    pac: PACReducer,
    mes: MESReducer,
    iis: IISReducer,
    dc: DCReducer,
    confrontable: ConfrontableReducer,
    funds: FundsReducer,
    settings: SettingsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
export type RootState = ReturnType<typeof store.getState>;

export const DateFormat = (unix: number): string => {
  if (unix) return new Date(unix * 1000).toISOString().split("T")[0];
  return "";
};

export const getYears = (
  start: number,
  end: number,
  paddingYears?: number
): { value: number; disabled?: boolean }[] => {
  const startYear = new Date(start * 1000).getFullYear() + 1;
  const endYear = new Date(end * 1000).getFullYear();
  let d = [];
  for (let i = startYear; i <= endYear; i++) {
    const disabled = endYear - i - (paddingYears || 0) <= 0;
    d.push({ value: i, disabled });
  }
  return d;
};

export function Sum(numbers: number[]): number {
  let s: number = 0;
  if (!isArray(numbers)) {
    console.log("Found numbers not numbers array");
    console.log(numbers);
    console.log(typeof numbers);
  }
  for (let i of numbers) {
    s = s + i;
  }
  return s;
}
export function Mean(numbers: number[]): number {
  const sum = Sum(numbers);
  return sum / numbers.length;
}
