import { v4 } from "uuid";
import { WorkerMessage } from "./types";

export class PromiseWorker {
  worker: Worker;
  constructor(worker: Worker) {
    this.worker = worker;
  }
  run(msg: Omit<WorkerMessage, "id">): Promise<WorkerMessage> {
    const id = v4();

    return new Promise((resolve, reject) => {
      const listener = (p: { data: WorkerMessage }) => {
        const { id: msgId } = p.data;
        if (id === msgId) {
          this.worker.removeEventListener("message", listener);
          return resolve(p.data);
        }
      };
      this.worker.addEventListener("message", listener);
      this.worker.postMessage({ ...msg, id });
    });
  }
}
