import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { Group } from "./groups/controller";

export interface AdminState {
  sectionLoaded: boolean;
  groups: Group[];
  error?: JsonResponseError;
}

const adminStore = createSlice({
  initialState: {
    sectionLoaded: false,
    groups: [],
  } as AdminState,
  reducers: {
    replaceGroup: (state, action: PayloadAction<Group>) => {
      for (let i = 0; i < state.groups.length; i++) {
        if (state.groups[i].id === action.payload.id) {
          state.groups[i] = { ...action.payload };
        }
      }
      state.error = undefined;
    },

    addIntoGroups: (state, action: PayloadAction<Group>) => {
      state.groups.push(action.payload);
      state.error = undefined;
    },
    error: (state, action: PayloadAction<JsonResponseError>) => {
      state.error = action.payload;
    },
    loadGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
      state.sectionLoaded = true;
      state.error = undefined;
    },
  },
  name: "admin",
});

export const adminReducer = adminStore.reducer;
export const adminActions = adminStore.actions;
