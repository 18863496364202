import { distance } from "fastest-levenshtein";

export default function fuzzysearch(
  needle: string,
  values: string | string[],
  minDistance: number = 2
): boolean {
  if (typeof values === "string") {
    const components = values.split(" ");
    if (components.length > 1) {
      return fuzzysearch(needle, components, minDistance);
    }
    if (values.toLowerCase().startsWith(needle.toLowerCase())) {
      return true;
    }
    return distance(needle, values) <= minDistance;
  } else {
    for (let val of values) {
      if (fuzzysearch(needle, val, minDistance)) {
        return true;
      }
    }
    return false;
  }
}
