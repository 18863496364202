import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { simulationType } from "components/molecols/simulationSelector";

const simulationStore = createSlice({
  name: "Simulations",
  initialState: {
    type: "PIC",
    timeline: {
      start: 0,
      simulationStart: 0,
      end: 0,
    },
  } as SimulationState,
  reducers: {
    changeType: (state, action: PayloadAction<{ type: simulationType }>) => {
      state.type = action.payload.type;
    },
    updateSimulationTimeline: (
      state,
      action: PayloadAction<{
        start: number;
        end: number;
        simulationStart: number;
      }>
    ) => {
      state.timeline = action.payload;
      if (state.timeline.simulationStart === 0) {
        state.timeline.simulationStart = state.timeline.start;
      }
    },
  },
});

export const SimulationActions = simulationStore.actions;
export const SimulationReducer = simulationStore.reducer;

export interface SimulationState {
  type: simulationType;
  timeline: SimulationTimeline;
}

export interface SimulationTimeline {
  start: number;
  simulationStart: number;
  end: number;
}
