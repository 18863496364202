import { config } from "config";
import { JsonResponseError, store, Sum } from "contorller";
import { authController } from "contorller/auth/controller";
import { maxNumber, minNumber } from "contorller/utils";
import { BaseController, Puppet } from "../controller";
import { ControllerSimulation, FundWeight, Snap } from "../types";
import { DCActions } from "./redux";

// Attiva la versione compattata del payload per una riduzione nel trasferimento.
const PRESS = true;

export interface DCRequest {
  start: number;
  end: number;
  // i fondi che verrano usati nella
  funds: FundWeight[];
  investment: number;
  // Durata dell'investimento espresso in mesi
  durationInvestment: number;
  // Durata del servizio espressa in mesi
  durationService: number;
  days: number[];
}
export interface DCResponse {
  index: number;
  total: number;
  request: DCRequest;
  snaps: Snap[];
  annual: number;
  absolute: number;
}

export interface DCController
  extends ControllerSimulation<DCRequest, DCResponse> {}

export const newDCController = (): DCController => concreteDC.getInstance();
export const dcController = (): DCController => concreteDC.getInstance();

class puppetDC implements Puppet<DCRequest, DCResponse> {
  private request?: DCRequest;

  callbackReady(): void {
    store.dispatch(DCActions.websocketSetReady());
  }
  callbackNotReady(): void {
    store.dispatch(DCActions.websocketSetNotReady());
  }

  async getUrl(): Promise<string> {
    const token = await authController().getAuther().getToken();
    return config().WS + "/dc/ws?token=" + token;
  }
  startRolling(request: DCRequest): void {
    store.dispatch(DCActions.start());
    this.request = request;
  }
  async startRollingFallback(request: DCRequest): Promise<any> {}

  async recvRolling(result: DCResponse[], all: DCResponse[]): Promise<void> {
    result.forEach((e) => {
      const request = this.request;
      store.dispatch(DCActions.update({ loaded: e.index, total: e.total }));
      if (e.request == null && request != null) {
        e.request = request;
      }
      if (e.index + 1 === e.total) {
        let absolutes = all.map<number>((e) => e.absolute);
        let annuals = all.map<number>((e) => e.annual);
        store.dispatch(
          DCActions.end({
            years: e.request.durationInvestment,
            absolute: {
              min: minNumber(absolutes),
              max: maxNumber(absolutes),
              avg: Sum(absolutes) / absolutes.length,
            },
            annual: {
              min: minNumber(annuals),
              max: maxNumber(annuals),
              avg: Sum(annuals) / annuals.length,
            },
          })
        );
      }
    });
  }
  error(error: JsonResponseError): void {
    store.dispatch(DCActions.setError(error));
  }
  clear(): void {
    store.dispatch(DCActions.reset());
  }
}
class concreteDC
  extends BaseController<DCRequest, DCResponse>
  implements DCController
{
  private constructor() {
    super("DC", new puppetDC());
  }

  private static _instance?: DCController;
  static getInstance(): DCController {
    if (!this._instance) {
      this._instance = new concreteDC();
    }
    return this._instance;
  }
}
