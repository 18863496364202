import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { EarnTable } from "../types";
import { PICDRequest } from "./controller";

export interface PICDState {
  websocketIsReady: boolean;
  inRunning: boolean;
  index: number;
  request: PICDRequest;
  response: {
    loaded: number;
    total: number;
    selected: number;
    table?: EarnTable;
  };
  error?: JsonResponseError;
}

const initialState: PICDState = {
  websocketIsReady: false,
  inRunning: false,
  index: 0,
  request: {
    simulation: "picd",
    start: 0,
    rebalance: 0,
    end: 0,
    funds: [],
    durationInvestment: 120,
    investment: 10000,
    rate: 1000,
    days: [1],
    monthFrequency: 1,
  },
  response: {
    loaded: 0,
    total: 0,
    selected: 0,
  },
};

const picdStore = createSlice({
  name: "picd",
  initialState,
  reducers: {
    websocketSetNotReady: (state) => {
      state.websocketIsReady = false;
    },
    websocketSetReady: (state) => {
      state.websocketIsReady = true;
    },
    setRequest: (state, action: PayloadAction<PICDRequest>) => {
      state.request = action.payload;
    },
    update: (
      state,
      action: PayloadAction<{ loaded: number; total: number }>
    ) => {
      state.response.loaded = action.payload.loaded;
      state.response.total = action.payload.total;
    },
    setError: (state, action: PayloadAction<JsonResponseError>) => {
      state.error = action.payload;
      state.inRunning = false;
    },
    start: (state) => {
      state.inRunning = true;
      state.response.loaded = 0;
      state.response.total = 0;
      state.response.table = undefined;
      state.index = 0;
      state.error = undefined;
    },
    setIndex(state, action: PayloadAction<{ index: number }>) {
      state.index = action.payload.index;
    },
    end: (state, action: PayloadAction<EarnTable>) => {
      state.inRunning = false;
      state.response.table = action.payload;
    },
    reset: () => initialState,
  },
});

export const PICDActions = picdStore.actions;
export const PICDReducer = picdStore.reducer;
