import { config } from "config";
import { JsonResponseError, store, Sum } from "contorller";
import { authController } from "contorller/auth/controller";
import { maxNumber, minNumber } from "contorller/utils";
import { BaseController, Puppet } from "../controller";
import { goalSeek } from "../GoalSeek/controller";
import {
  ControllerSimulation,
  MultipleGoalResponse,
  PACRequest,
  PACResponse
} from "../types";
import { pacWithSpoToGoalSeekRequests } from "../utils";
import { PACActions } from "./redux";
import { calcSpotOperation } from "./spot";
import { pacAvgAnnualRequest } from "./utils";

export interface PACController
  extends ControllerSimulation<PACRequest, PACResponse> {
  //calcWithSpotOperations(investments: SpotOperation[], opts?: { debug?: boolean }): void
  selectPAC(): void;
  //removeInvestments(id: string): void
  clearAllOperationsSpot(): void;
  getPac(): PACResponse;
  getSpotEarn(
    pac: PACResponse,
  ): Promise<MultipleGoalResponse | JsonResponseError>;
  // csvDownload(): void;
}
export const pacController = (): PACController =>
  concretePacController.getInstance();

class puppetPac implements Puppet<PACRequest, PACResponse> {
  private request?: PACRequest;

  callbackReady(): void {
    store.dispatch(PACActions.websocketReady());
  }
  callbackNotReady(): void {
    store.dispatch(PACActions.websocketNotReady());
  }
  async getUrl(): Promise<string> {
    const token = await authController().getAuther().getToken();
    return config().WS + "/pac/ws?token=" + token;
  }
  startRolling(request: PACRequest): void {
    this.request = request;

    store.dispatch(PACActions.start());
  }
  async startRollingFallback(request: PACRequest): Promise<any> {
    this.request = request;

    store.dispatch(PACActions.startSimulation({ total: 1 }));

    const response = await fetch(config().URL + "/pac/", {
      headers: await authController().getAuther().getHeaderToken(),
      method: "POST",
      body: JSON.stringify(request),
    });

    if (response.status === 200) {
      const { simulations } = await response.json();
      const sims = simulations.map((d: any) => ({
        ...d,
        request,
      }));
      await this.endSimulation(sims);
      return sims;
    } else {
      return [];
    }
  }

  private async endSimulation(simulations: PACResponse[]) {
    let avgRequest = pacAvgAnnualRequest(simulations);
    let resp = await goalSeek.single(avgRequest);
    let annuals = simulations.map((e) => e.annual);
    let absolutes = simulations.map((e) => e.absolute);
    let request = this.request;
    if (request != null) {
      store.dispatch(
        PACActions.endSimulation({
          table: {
            years:
              request.durationInvestment / 12 + request.differimentMounth / 12,
            absolute: {
              min: minNumber(absolutes),
              max: maxNumber(absolutes),
              avg: Sum(absolutes) / absolutes.length,
            },
            annual: {
              min: minNumber(annuals),
              max: maxNumber(annuals),
              avg: resp.value,
            },
          },
        }),
      );
    }
  }

  async recvRolling(result: PACResponse[], all: PACResponse[]): Promise<void> {
    result.forEach(async (e, index) => {
      if (this.request) {
        e.request = this.request;
      }

      // If first simulation received.
      if (all.length === 1) {
        store.dispatch(PACActions.startSimulation({ total: e.total }));
      }

      if (e.index > 0 && e.index + 1 !== e.total) {
        store.dispatch(PACActions.updateSimulation({ index: all.length - 1 }));
      }

      if (all.length === e.total) {
        await this.endSimulation(all);
        store.dispatch(PACActions.end());
      }
    });
  }
  error(error: JsonResponseError): void {
    store.dispatch(PACActions.error({ error: error }));
  }
  clear(): void {
    store.dispatch(PACActions.reset());
  }
}

class concretePacController extends BaseController<PACRequest, PACResponse> {
  private static _instance?: PACController;
  private constructor() {
    super("PAC", new puppetPac());
    store.subscribe(() => {
      if (this.pac) {
        const spots = store.getState().pac.spots.operations;
        calcSpotOperation(this.getPac(), spots);
      }
    });
  }
  static getInstance(): PACController {
    if (!concretePacController._instance) {
      concretePacController._instance = new concretePacController();
    }
    return concretePacController._instance;
  }

  pac?: PACResponse;
  clearAllOperationsSpot(): void {
    store.dispatch(PACActions.hideSpot());
    this.pac = undefined;
  }
  getPac(): PACResponse {
    if (this.pac === undefined)
      throw new Error("Errore non hai caricato nessuna simulazione pac");
    else return this.pac;
  }

  selectPAC() {
    let index = store.getState().pac.index;
    if (this.getSimulations().length - 1 < index) {
      throw new Error("non esite questa simulazione");
    }
    this.pac = {
      ...this.getSimulations()[index],

      simulationsWithSpots: [],
    };
    this.pac.request.start = this.pac.snaps[0].unix;
    this.pac.request.end = this.pac.snaps[this.pac.snaps.length - 1].unix;
    calcSpotOperation(this.getPac(), []);
    store.dispatch(PACActions.showSpot());
  }

  async getSpotEarn(pac: PACResponse) {
    let requests = pacWithSpoToGoalSeekRequests(pac);
    return goalSeek.multiple(requests);
  }
}
