const bb = [
  {
    ISIN: "IE00BJYLJ716",
    PIC: "ZK0",
    PAC: "ZK5",
  },
  {
    ISIN: "IE00B8C2R429",
    PIC: "WJ0",
    PAC: "WJ5",
  },
  {
    ISIN: "IE00B9F6SB25",
    PIC: "WW0",
    PAC: "",
  },
  {
    ISIN: "IE00B9CQ9016",
    PIC: "WL0",
    PAC: "WL5",
  },
  {
    ISIN: "IE00B921S769",
    PIC: "WY0",
    PAC: "",
  },
  {
    ISIN: "IE00B95T3S38",
    PIC: "WK0",
    PAC: "WK5",
  },
  {
    ISIN: "IE00B9503F31",
    PIC: "WX0",
    PAC: "",
  },
  {
    ISIN: "IE00B83GYM07",
    PIC: "WM0",
    PAC: "WM5",
  },
  {
    ISIN: "IE00B959YH93",
    PIC: "WZ0",
    PAC: "",
  },
  {
    ISIN: "IE00B3WM4L37",
    PIC: "VJ0",
    PAC: "VJ5",
  },
  {
    ISIN: "IE00B4528T37",
    PIC: "VT0",
    PAC: "",
  },
  {
    ISIN: "IE00B434CK08",
    PIC: "VX0",
    PAC: "VX5",
  },
  {
    ISIN: "IE00B3W2C208",
    PIC: "VU0",
    PAC: "",
  },
  {
    ISIN: "IE00B658BK73",
    PIC: "VY0",
    PAC: "VY5",
  },
  {
    ISIN: "IE00B3L7LQ33",
    PIC: "VV0",
    PAC: "",
  },
  {
    ISIN: "IE00B5V5F369",
    PIC: "VZ0",
    PAC: "VZ5",
  },
  {
    ISIN: "IE00B55WL680",
    PIC: "VW0",
    PAC: "",
  },
  {
    ISIN: "IE0032080495",
    PIC: "UN0",
    PAC: "UN5",
  },
  {
    ISIN: "IE0030608966",
    PIC: "TY0",
    PAC: "",
  },
  {
    ISIN: "IE00B3BF0S24",
    PIC: "VD0",
    PAC: "VD5",
  },
  {
    ISIN: "IE00B3BF0T31",
    PIC: "VH0",
    PAC: "",
  },
  {
    ISIN: "IE00BYZ2Y955",
    PIC: "ZG0",
    PAC: "ZG5",
  },
  {
    ISIN: "IE00BYZ2YC82",
    PIC: "ZT0",
    PAC: "",
  },
  {
    ISIN: "IE00BYZ2YB75",
    PIC: "ZH0",
    PAC: "ZH5",
  },
  {
    ISIN: "IE00BYZ2YD99",
    PIC: "ZU0",
    PAC: "",
  },
  {
    ISIN: "IE0005380518",
    PIC: "TD0",
    PAC: "TD5",
  },
  {
    ISIN: "IE0009624234",
    PIC: "TQ0",
    PAC: "",
  },
  {
    ISIN: "IE00BJYLJB50",
    PIC: "YL0",
    PAC: "YL5",
  },
  {
    ISIN: "IE00BJYLJD74",
    PIC: "YM0",
    PAC: "YM5",
  },
  {
    ISIN: "IE00BJYLJC67",
    PIC: "YY0",
    PAC: "YY5",
  },
  {
    ISIN: "IE00BJYLJF98",
    PIC: "YZ0",
    PAC: "YZ5",
  },
  {
    ISIN: "IE00B3S9CB42",
    PIC: "VN0",
    PAC: "VN5",
  },
  {
    ISIN: "IE00B3TV4Y62",
    PIC: "VR0",
    PAC: "",
  },
  {
    ISIN: "IE00BVL88F07",
    PIC: "XH0",
    PAC: "XH5",
  },
  {
    ISIN: "IE00BVL88K59",
    PIC: "XU0",
    PAC: "",
  },
  {
    ISIN: "IE00BVL88J45",
    PIC: "XI0",
    PAC: "XI5",
  },
  {
    ISIN: "IE00BVL88N80",
    PIC: "XV0",
    PAC: "",
  },
  {
    ISIN: "IE00BVL88G14",
    PIC: "XJ0",
    PAC: "XJ5",
  },
  {
    ISIN: "IE00BVL88L66",
    PIC: "XX0",
    PAC: "",
  },
  {
    ISIN: "IE00BVL88H21",
    PIC: "XK0",
    PAC: "XK5",
  },
  {
    ISIN: "IE00BVL88M73",
    PIC: "XY0",
    PAC: "",
  },
  {
    ISIN: "IE0032080503",
    PIC: "UO0",
    PAC: "UO5",
  },
  {
    ISIN: "IE0030609048",
    PIC: "TZ0",
    PAC: "",
  },
  {
    ISIN: "IE00B04KP775",
    PIC: "UV0",
    PAC: "UV5",
  },
  {
    ISIN: "IE00B02YQJ08",
    PIC: "UG0",
    PAC: "",
  },
  {
    ISIN: "IE00B9G50M30",
    PIC: "WF0",
    PAC: "WF5",
  },
  {
    ISIN: "IE00B9G61455",
    PIC: "WS0",
    PAC: "",
  },
  {
    ISIN: "IE00B7M9HB33",
    PIC: "WE0",
    PAC: "WE5",
  },
  {
    ISIN: "IE00B9G58527",
    PIC: "WR0",
    PAC: "",
  },
  {
    ISIN: "IE0030608859",
    PIC: "TJ0",
    PAC: "TJ5",
  },
  {
    ISIN: "IE0030618114",
    PIC: "TW0",
    PAC: "",
  },
  {
    ISIN: "IE0030618007",
    PIC: "TK0",
    PAC: "TK5",
  },
  {
    ISIN: "IE0030618221",
    PIC: "TX0",
    PAC: "",
  },
  {
    ISIN: "IE0005372291",
    PIC: "TB0",
    PAC: "TB5",
  },
  {
    ISIN: "IE0009623939",
    PIC: "TO0",
    PAC: "",
  },
  {
    ISIN: "IE00B04KP445",
    PIC: "UR0",
    PAC: "UR5",
  },
  {
    ISIN: "IE00B02YQB22",
    PIC: "UC0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXS238",
    PIC: "YD0",
    PAC: "YD5",
  },
  {
    ISIN: "IE00BYVXS675",
    PIC: "YQ0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXS451",
    PIC: "YE0",
    PAC: "YE5",
  },
  {
    ISIN: "IE00BYVXS899",
    PIC: "YR0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXS345",
    PIC: "YF0",
    PAC: "YF5",
  },
  {
    ISIN: "IE00BYVXS782",
    PIC: "YS0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXS568",
    PIC: "YG0",
    PAC: "YG5",
  },
  {
    ISIN: "IE00BYVXS907",
    PIC: "YT0",
    PAC: "",
  },
  {
    ISIN: "IE00BJYLJG06",
    PIC: "ZV0",
    PAC: "ZV5",
  },
  {
    ISIN: "IE00BVL88501",
    PIC: "XL0",
    PAC: "XL5",
  },
  {
    ISIN: "IE00BVL88949",
    PIC: "XW0",
    PAC: "",
  },
  {
    ISIN: "IE00BVL88618",
    PIC: "YA0",
    PAC: "YA5",
  },
  {
    ISIN: "IE00BVL88B68",
    PIC: "YN0",
    PAC: "",
  },
  {
    ISIN: "IE00BLDGBM04",
    PIC: "ZW0",
    PAC: "ZW5",
  },
  {
    ISIN: "IE00BLDGBN11",
    PIC: "ZX0",
    PAC: "ZX5",
  },
  {
    ISIN: "IE0005359660",
    PIC: "TG0",
    PAC: "TG5",
  },
  {
    ISIN: "IE0009625199",
    PIC: "TT0",
    PAC: "",
  },
  {
    ISIN: "IE00B04KP668",
    PIC: "UT0",
    PAC: "UT5",
  },
  {
    ISIN: "IE00B02YQG76",
    PIC: "UE0",
    PAC: "",
  },
  {
    ISIN: "IE0005359991",
    PIC: "TH0",
    PAC: "TH5",
  },
  {
    ISIN: "IE0009625207",
    PIC: "TU0",
    PAC: "",
  },
  {
    ISIN: "IE00B054SV30",
    PIC: "UU0",
    PAC: "UU5",
  },
  {
    ISIN: "IE00B02YQH83",
    PIC: "UF0",
    PAC: "",
  },
  {
    ISIN: "IE00BMZN0T66",
    PIC: "ZY0",
    PAC: "ZY5",
  },
  {
    ISIN: "IE00BMZN0V88",
    PIC: "ZZ0",
    PAC: "ZZ5",
  },
  {
    ISIN: "IE00BJYLJ823",
    PIC: "ZL0",
    PAC: "ZL5",
  },
  {
    ISIN: "IE00BJYLJ930",
    PIC: "ZM0",
    PAC: "ZM5",
  },
  {
    ISIN: "IE00B943L826",
    PIC: "XA0",
    PAC: "XA5",
  },
  {
    ISIN: "IE00B95T6282",
    PIC: "XN0",
    PAC: "",
  },
  {
    ISIN: "IE00B955KM47",
    PIC: "XC0",
    PAC: "XC5",
  },
  {
    ISIN: "IE00B87PWY18",
    PIC: "XP0",
    PAC: "",
  },
  {
    ISIN: "IE00B94H8N85",
    PIC: "XB0",
    PAC: "XB5",
  },
  {
    ISIN: "IE00B84HMD91",
    PIC: "XO0",
    PAC: "",
  },
  {
    ISIN: "IE00B9BM8039",
    PIC: "XD0",
    PAC: "XD5",
  },
  {
    ISIN: "IE00B8DTV951",
    PIC: "XQ0",
    PAC: "",
  },
  {
    ISIN: "IE00B95WD600",
    PIC: "WI0",
    PAC: "WI5",
  },
  {
    ISIN: "IE00B8KGMC91",
    PIC: "WV0",
    PAC: "",
  },
  {
    ISIN: "IE000ACFO6B6",
    PIC: "HA0",
    PAC: "HA5",
  },
  {
    ISIN: "IE000H4P2KR2",
    PIC: "HB0",
    PAC: "HB5",
  },
  {
    ISIN: "IE000V4RVQ80",
    PIC: "HC0",
    PAC: "HC5",
  },
  {
    ISIN: "IE000X6G5UV2",
    PIC: "HD0",
    PAC: "HD5",
  },
  {
    ISIN: "IE00BYVXRV56",
    PIC: "ZA0",
    PAC: "ZA5",
  },
  {
    ISIN: "IE00BYVXRZ94",
    PIC: "ZN0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXRX70",
    PIC: "ZB0",
    PAC: "ZB5",
  },
  {
    ISIN: "IE00BYVXSS92",
    PIC: "ZO0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXRW63",
    PIC: "ZC0",
    PAC: "ZC5",
  },
  {
    ISIN: "IE00BYVXS014",
    PIC: "ZP0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXRY87",
    PIC: "ZD0",
    PAC: "ZD5",
  },
  {
    ISIN: "IE00BYVXS121",
    PIC: "ZQ0",
    PAC: "",
  },
  {
    ISIN: "IE00B91SH939",
    PIC: "WG0",
    PAC: "WG5",
  },
  {
    ISIN: "IE00B9M3LG26",
    PIC: "WU0",
    PAC: "",
  },
  {
    ISIN: "IE00B8QW4Z80",
    PIC: "WH0",
    PAC: "WH5",
  },
  {
    ISIN: "IE00B96F2G14",
    PIC: "WT0",
    PAC: "",
  },
  {
    ISIN: "IE00BG0D0J96",
    PIC: "ZJ0",
    PAC: "ZJ5",
  },
  {
    ISIN: "IE00BG0D0K02",
    PIC: "ZI0",
    PAC: "ZI5",
  },
  {
    ISIN: "IE00B2NLMT64",
    PIC: "UY0",
    PAC: "UY5",
  },
  {
    ISIN: "IE00B2NLMW93",
    PIC: "UJ0",
    PAC: "",
  },
  {
    ISIN: "IE00B2NLMV86",
    PIC: "UZ0",
    PAC: "UZ5",
  },
  {
    ISIN: "IE00B2NLMX01",
    PIC: "UK0",
    PAC: "",
  },
  {
    ISIN: "IE000B88M711",
    PIC: "HI0",
    PAC: "HI5",
  },
  {
    ISIN: "IE000Q0R8W34",
    PIC: "HL0",
    PAC: "HL5",
  },
  {
    ISIN: "IE00B6SBTN25",
    PIC: "WB0",
    PAC: "WB5",
  },
  {
    ISIN: "IE00B6SCG018",
    PIC: "WO0",
    PAC: "",
  },
  {
    ISIN: "IE00B6SF8487",
    PIC: "WA0",
    PAC: "WA5",
  },
  {
    ISIN: "IE00B62NM683",
    PIC: "WN0",
    PAC: "",
  },
  {
    ISIN: "IE0005372309",
    PIC: "TC0",
    PAC: "TC5",
  },
  {
    ISIN: "IE0009624127",
    PIC: "TP0",
    PAC: "",
  },
  {
    ISIN: "IE00B04KP551",
    PIC: "US0",
    PAC: "US5",
  },
  {
    ISIN: "IE00B02YQD46",
    PIC: "UD0",
    PAC: "",
  },
  {
    ISIN: "IE00B3BF0L54",
    PIC: "VF0",
    PAC: "VF5",
  },
  {
    ISIN: "IE00B3BF0N78",
    PIC: "VK0",
    PAC: "",
  },
  {
    ISIN: "IE00B3BF0M61",
    PIC: "VE0",
    PAC: "VE5",
  },
  {
    ISIN: "IE00B3BF0P92",
    PIC: "VI0",
    PAC: "",
  },
  {
    ISIN: "IE00B7WJLM29",
    PIC: "WD0",
    PAC: "WD5",
  },
  {
    ISIN: "IE00B8P30099",
    PIC: "WQ0",
    PAC: "",
  },
  {
    ISIN: "IE00B7VTCC05",
    PIC: "WC0",
    PAC: "WC5",
  },
  {
    ISIN: "IE00B8KGN042",
    PIC: "WP0",
    PAC: "",
  },
  {
    ISIN: "IE00BCZNHK63",
    PIC: "XG0",
    PAC: "XG5",
  },
  {
    ISIN: "IE00BCZNHM87",
    PIC: "XT0",
    PAC: "",
  },
  {
    ISIN: "IE00BCZNHL70",
    PIC: "XF0",
    PAC: "XF5",
  },
  {
    ISIN: "IE00BCZNHN94",
    PIC: "XS0",
    PAC: "",
  },
  {
    ISIN: "IE0005372184",
    PIC: "TA0",
    PAC: "TA5",
  },
  {
    ISIN: "IE0009623822",
    PIC: "TN0",
    PAC: "",
  },
  {
    ISIN: "IE00B04KP338",
    PIC: "UQ0",
    PAC: "UQ5",
  },
  {
    ISIN: "IE00B02YQ901",
    PIC: "UB0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXSB26",
    PIC: "YH0",
    PAC: "YH5",
  },
  {
    ISIN: "IE00BYVXSG70",
    PIC: "YU0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXSD40",
    PIC: "YI0",
    PAC: "YI5",
  },
  {
    ISIN: "IE00BYVXSJ02",
    PIC: "YV0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXSC33",
    PIC: "YJ0",
    PAC: "YJ5",
  },
  {
    ISIN: "IE00BYVXSH87",
    PIC: "YW0",
    PAC: "",
  },
  {
    ISIN: "IE00BYVXSF63",
    PIC: "YK0",
    PAC: "YK5",
  },
  {
    ISIN: "IE00BYVXSK17",
    PIC: "YX0",
    PAC: "",
  },
  {
    ISIN: "IE000K6M66I3",
    PIC: "HG0",
    PAC: "HG5",
  },
  {
    ISIN: "IE000TCXNA99",
    PIC: "HM0",
    PAC: "HM5",
  },
  {
    ISIN: "IE000KGC4J99",
    PIC: "HN0",
    PAC: "HN5",
  },
  {
    ISIN: "IE000ODBVC71",
    PIC: "HE0",
    PAC: "HE5",
  },
  {
    ISIN: "IE0001SZEVN6",
    PIC: "HF0",
    PAC: "HF5",
  },
];

const ch = [
  {
    ISIN: "IE0004479642",
    PIC: "MB0",
    PAC: "MB5",
  },
  {
    ISIN: "IE0004480194",
    PIC: "OB0",
    PAC: "",
  },
  {
    ISIN: "IE0004462408",
    PIC: "LY0",
    PAC: "LY5",
  },
  {
    ISIN: "IE0004464008",
    PIC: "NY0",
    PAC: "",
  },
  {
    ISIN: "IE0004878744",
    PIC: "LF0",
    PAC: "LF5",
  },
  {
    ISIN: "IE0004394882",
    PIC: "NF0",
    PAC: "",
  },
  {
    ISIN: "IE0004460683",
    PIC: "LX0",
    PAC: "LX5",
  },
  {
    ISIN: "IE0004461095",
    PIC: "NX0",
    PAC: "",
  },
  {
    ISIN: "IE0004905935",
    PIC: "LN0",
    PAC: "LN5",
  },
  {
    ISIN: "IE0004405654",
    PIC: "NN0",
    PAC: "",
  },
  {
    ISIN: "IE0004906016",
    PIC: "LO0",
    PAC: "LO5",
  },
  {
    ISIN: "IE0004405878",
    PIC: "NO0",
    PAC: "",
  },
  {
    ISIN: "IE0004879379",
    PIC: "LH0",
    PAC: "LH5",
  },
  {
    ISIN: "IE0004404798",
    PIC: "NH0",
    PAC: "",
  },
  {
    ISIN: "IE0004880377",
    PIC: "LI0",
    PAC: "LI5",
  },
  {
    ISIN: "IE0004404806",
    PIC: "NI0",
    PAC: "",
  },
  {
    ISIN: "IE0004878967",
    PIC: "LC0",
    PAC: "LC5",
  },
  {
    ISIN: "IE0004393926",
    PIC: "NC0",
    PAC: "",
  },
  {
    ISIN: "IE00B604T843",
    PIC: "MW0",
    PAC: "MW5",
  },
  {
    ISIN: "IE00B609PZ88",
    PIC: "OE0",
    PAC: "",
  },
  {
    ISIN: "IE0004488262",
    PIC: "MC0",
    PAC: "MC5",
  },
  {
    ISIN: "IE0004490672",
    PIC: "OC0",
    PAC: "",
  },
  {
    ISIN: "IE0004457085",
    PIC: "LJ0",
    PAC: "LJ5",
  },
  {
    ISIN: "IE0004457200",
    PIC: "NJ0",
    PAC: "",
  },
  {
    ISIN: "IE00B60CK411",
    PIC: "QB0",
    PAC: "QB5",
  },
  {
    ISIN: "IE00B601LX41",
    PIC: "OY0",
    PAC: "",
  },
  {
    ISIN: "IE0004906123",
    PIC: "LP0",
    PAC: "LP5",
  },
  {
    ISIN: "IE0004406397",
    PIC: "NP0",
    PAC: "",
  },
  {
    ISIN: "IE00B603QV38",
    PIC: "QC0",
    PAC: "QC5",
  },
  {
    ISIN: "IE00B605P459",
    PIC: "OZ0",
    PAC: "",
  },
  {
    ISIN: "IE0004906909",
    PIC: "LQ0",
    PAC: "LQ5",
  },
  {
    ISIN: "IE0004406629",
    PIC: "NQ0",
    PAC: "",
  },
  {
    ISIN: "IE0032082988",
    PIC: "MJ0",
    PAC: "MJ5",
  },
  {
    ISIN: "IE0030608636",
    PIC: "OJ0",
    PAC: "",
  },
  {
    ISIN: "IE00B60DM373",
    PIC: "QD0",
    PAC: "QD5",
  },
  {
    ISIN: "IE00B602Q215",
    PIC: "PB0",
    PAC: "",
  },
  {
    ISIN: "IE00B60DMK45",
    PIC: "MZ0",
    PAC: "MZ5",
  },
  {
    ISIN: "IE00B603X672",
    PIC: "OL0",
    PAC: "",
  },
  {
    ISIN: "IE0004880591",
    PIC: "LL0",
    PAC: "LL5",
  },
  {
    ISIN: "IE0004404913",
    PIC: "NL0",
    PAC: "",
  },
  {
    ISIN: "IE00B608CJ84",
    PIC: "QA0",
    PAC: "QA5",
  },
  {
    ISIN: "IE00B603Z495",
    PIC: "OO0",
    PAC: "",
  },
  {
    ISIN: "IE0004880609",
    PIC: "LMO",
    PAC: "LM5",
  },
  {
    ISIN: "IE0004405092",
    PIC: "NM0",
    PAC: "",
  },
  {
    ISIN: "IE0004905604",
    PIC: "LE0",
    PAC: "LE5",
  },
  {
    ISIN: "IE0004394007",
    PIC: "NE0",
    PAC: "",
  },
  {
    ISIN: "IE0004878637",
    PIC: "LG0",
    PAC: "LG5",
  },
  {
    ISIN: "IE0004400374",
    PIC: "NG0",
    PAC: "",
  },
  {
    ISIN: "IE0004622134",
    PIC: "MH0",
    PAC: "MH5",
  },
  {
    ISIN: "IE0004622241",
    PIC: "OH0",
    PAC: "",
  },
  {
    ISIN: "IE0004878413",
    PIC: "LB0",
    PAC: "LB5",
  },
  {
    ISIN: "IE0004393819",
    PIC: "NB0",
    PAC: "",
  },
  {
    ISIN: "IE00B6072284",
    PIC: "LWO",
    PAC: "LW5",
  },
  {
    ISIN: "IE00B601NV66",
    PIC: "NW0",
    PAC: "",
  },
  {
    ISIN: "IE0004878520",
    PIC: "LD0",
    PAC: "LD5",
  },
  {
    ISIN: "IE0004394114",
    PIC: "NO0",
    PAC: "",
  },
  {
    ISIN: "IE00B608L247",
    PIC: "MYO",
    PAC: "MY5",
  },
  {
    ISIN: "IE00B6085F58",
    PIC: "OF0",
    PAC: "",
  },
  {
    ISIN: "IE00BD6GZG34",
    PIC: "QF0",
    PAC: "QF5",
  },
  {
    ISIN: "IE00B13C3B10",
    PIC: "OW0",
    PAC: "",
  },
  {
    ISIN: "IE00BFX0ZD94",
    PIC: "QE0",
    PAC: "QE5",
  },
  {
    ISIN: "IE00B784PD08",
    PIC: "PC0",
    PAC: "",
  },
  {
    ISIN: "IE0030608297",
    PIC: "MS0",
    PAC: "MS5",
  },
  {
    ISIN: "IE0030617926",
    PIC: "OS0",
    PAC: "",
  },
  {
    ISIN: "IE0004621052",
    PIC: "MG0",
    PAC: "MG5",
  },
  {
    ISIN: "IE0004621276",
    PIC: "OG0",
    PAC: "",
  },
];

const gest = [
  {
    ISIN: "IT0005066870",
    PIC: 270,
    PAC: "",
  },
  {
    ISIN: "IT0001046892",
    PIC: 260,
    PAC: "",
  },
  {
    ISIN: "IT0005066896",
    PIC: "IG0",
    PAC: "IG5",
  },
  {
    ISIN: "IT0001178125",
    PIC: "IN0",
    PAC: "IN5",
  },
  {
    ISIN: "IT0005066912",
    PIC: 280,
    PAC: 285,
  },
  {
    ISIN: "IT0000386166",
    PIC: 240,
    PAC: 245,
  },
  {
    ISIN: "IT0000380185",
    PIC: 300,
    PAC: 305,
  },
  {
    ISIN: "IT0001019329",
    PIC: 250,
    PAC: 255,
  },
  {
    ISIN: "IT0001019329",
    PIC: "PY0",
    PAC: "PY5",
  },
  {
    ISIN: "IT0005066938",
    PIC: "CS0",
    PAC: "CS5",
  },
  {
    ISIN: "IT0004931157",
    PIC: "CR0",
    PAC: "CR5",
  },
  {
    ISIN: "IT0005066953",
    PIC: "SW0",
    PAC: "SW5",
  },
  {
    ISIN: "IT0004966971",
    PIC: "P10",
    PAC: "P15",
  },
  {
    ISIN: "IT0004966971",
    PIC: "SV0",
    PAC: "SV5",
  },
  {
    ISIN: "IT0005066953",
    PIC: "PR0",
    PAC: "PR5",
  },
];

export default [...bb, ...ch, ...gest];
