import Dexie, { Table } from "dexie";

export interface FundHistory {
  fundId: string;
  endDate: number;
  startDate: number;
  lastUpdate: number;
  values: number[];
}

export class FundHistoryDexie extends Dexie {
  histories!: Table<FundHistory>;

  constructor() {
    super("Empirich");
    this.version(1).stores({
      histories: "++fundId, name, endDate, startDate, lastUpdate, values", // Primary key and indexed props
    });
  }
}

export const db = new FundHistoryDexie();
