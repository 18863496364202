import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { EarnTable } from "../types";

export interface MonetaryFund {
  name: string;
  id: string;
  isin: string;
  defaultFund?: boolean;
}
export interface IISState {
  monetaryFunds: MonetaryFund[];
  defaultMonetaryFund: MonetaryFund;
  error?: JsonResponseError;
  index: number;
  total: number;
  loading: boolean;

  profiling: {
    start: number;
    end: number;
  };
  earnTable: EarnTable;
  wsReady: boolean;
  indexSimulationSelected: number;
}
const initialState: IISState = {
  wsReady: true,
  profiling: {
    start: 0,
    end: 0,
  },
  defaultMonetaryFund: {
    id: "",
    isin: "",
    name: "",
  },
  index: 0,
  total: 0,
  earnTable: {
    absolute: { min: 0, max: 0, avg: 0 },
    annual: { min: 0, max: 0, avg: 0 },
  },
  monetaryFunds: [],
  loading: false,
  indexSimulationSelected: 0,
};

const iisStore = createSlice({
  name: "iis",
  reducers: {
    loadMonetaryFunds: (
      state,
      action: PayloadAction<{ funds: MonetaryFund[] }>
    ) => {
      state.monetaryFunds = action.payload.funds;
      state.defaultMonetaryFund =
        action.payload.funds.find((f) => f.defaultFund === true) ??
        action.payload.funds[0];
    },
    startSimulation: (state, action: PayloadAction<{ total?: number }>) => {
      state.total = action.payload.total ?? 0;
      state.loading = true;
      state.error = undefined;
      state.indexSimulationSelected = state.total === 0 ? 0 : state.total - 1;
      state.profiling.start = Date.now();
    },
    selectSimulation: (state, action: PayloadAction<{ index: number }>) => {
      if (action.payload.index >= state.total) {
        state.indexSimulationSelected = state.total - 1;
      } else {
        state.indexSimulationSelected = action.payload.index;
      }
    },
    updateSimulation: (state, action: PayloadAction<{ index: number }>) => {
      state.index = action.payload.index;
    },
    endSimulation: (state) => {
      state.loading = false;
      state.profiling.end = Date.now();
      state.index = state.total;
      console.log(`IIS in ${state.profiling.end - state.profiling.start} ms `);
    },
    tableEarn: (state, action: PayloadAction<{ table: EarnTable }>) => {
      console.log(action.payload.table);
      state.earnTable = action.payload.table;
    },
    websocketIsReady: (state) => {
      state.wsReady = true;
      state.error = undefined;
    },
    websocketIsNotReady: (state) => {
      state.wsReady = false;
    },
    error: (state, action: PayloadAction<{ error: JsonResponseError }>) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    reset: (state) => {
      return {
        ...initialState,
        monetaryFunds: state.monetaryFunds,
        defaultMonetaryFund: state.defaultMonetaryFund,
      };
    },
    ok: (state) => {
      state.error = undefined;
    },
  },
  initialState,
});

export const IISActions = iisStore.actions;
export const IISReducer = iisStore.reducer;
