import { useQuery } from "@tanstack/react-query";
import { SpotResult } from "components/atoms/SpotResults";
import {
  SimulationKind,
  useSimulationContext,
} from "components/organismes/WindowSimulation/useSimulationContext";
import { RootState } from "contorller";
import { FundHistory } from "contorller/history/db";
import { getFundData } from "contorller/history/downloader";
import { Portfolio } from "contorller/portfolio/types";
import { goalSeek } from "contorller/simulations/GoalSeek/controller";
import { SpotOperation } from "contorller/simulations/PAC/redux";
import { GoalRequest } from "contorller/simulations/types";
import { clone, compact, debounce, first, isEmpty, last, set } from "lodash";
import { DateTime } from "luxon";
import Matrix from "ml-matrix";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { additionals } from "simulator/additionals";
import { PromiseWorker } from "simulator/promise";
import { convertRollingData, rolling } from "simulator/rolling";
import {
  AMOUNT,
  DATES,
  INVESTED,
  OperationKind,
  RelativeOperation,
  WorkerMessageType,
  convertResult,
  convertSingleFundsResult,
} from "simulator/types";
import { useDebounce } from "use-debounce";
import { v4 } from "uuid";
import SimulatorWorker from "../worker.js?worker";
import { usePicCharts } from "./pic/charts";
import {
  getAbsoluteYield,
  getLastAmount,
  getLastInvestedAmount,
  getYearlyYield,
  useEarnTable,
} from "./pic/earnTable";
import { FundLegendItem, useGetLegendItems } from "./pic/legendItems";
import { calculatePicOverview } from "./pic/overview";

const DEF_FUND_ID = "855c8138-3014-5fe3-8923-080edce2bb98";
const worker = new PromiseWorker(new SimulatorWorker());

interface DateWindow {
  start: DateTime;
  end: DateTime;
}

export function usePicRunner(isRealPic: boolean, portfolio?: Portfolio) {
  const spotOperations = useSelector<RootState, SpotOperation[]>(
    (state) => state.pic.spots.operations
  );
  const [overview, setOverview] = useState<RealPicOverview | null>(null);
  const [intervalKind, setIntervalKind] = useState("months");
  const [interval, setIntervalAmount] = useState(1);
  const isPendingAdditionals = useRef<string | null>(null);
  const [yearlyAdditionals, setYearlyAdditionals] = useState<number[]>([]);
  const [legendItems, setLegendItems] = useState<FundLegendItem[]>([]);
  const [durationKind, setDurationKind] = useState("years");
  const [duration, setDurationAmount] = useState(1);
  const [window, setWindow] = useState<DateWindow>({
    start: DateTime.local(),
    end: DateTime.local(),
  });
  const [rollingStartDate, setRollingStartDate] = useState<DateTime>(
    DateTime.local()
  );

  const [minStartDate, setMinStartDate] = useState<DateTime>(DateTime.local());
  const [startDate, setStartDate] = useState<DateTime>(DateTime.local());
  const [endDate, setEndDate] = useState<DateTime>(DateTime.local());
  const [maxEndDate, setMaxEndDate] = useState<DateTime>(DateTime.local());
  const [operations, setOperations] = useState<RelativeOperation[]>([]);
  const intervalValue = `${interval}-${intervalKind}`;
  const [rollingData, setRollingData] = useState<Matrix[]>([]);
  const [additionalsLineData, setAdditionalsLineData] = useState<Matrix[]>([]);
  const [lineData, setLineData] = useState<Matrix | null>(null);
  const [fundsData, setFundsData] = useState<Matrix[] | null>(null);
  const [absolute, setAbsolute] = useState<number>(0);
  const [finalAmount, setFinalAmount] = useState<number>(0);
  const [finalInvestedAmount, setfinalInvestedAmount] = useState<number>(0);
  const [yearly, setYearly] = useState<number>(0);
  const { pic, onChangeRequest } = useSimulationContext();
  const getLegendItems = useGetLegendItems();
  const shouldFixQuotes = useSelector<RootState, boolean>(
    (s) => s.settings.shouldFixQuotes
  );
  const showSingleFunds = useSelector<RootState, boolean>(
    (s) => s.pic.graphOptions.showSingleFunds
  );

  const amount = pic.initialInvestment;
  const shouldDeflate = pic.showInflation ?? false;
  const showInterestsCompare = pic.showInterestsCompare ?? false;
  const interestsCompareFundId = pic.interestsCompareFundId;
  const { fixedRate, annualCommission } = pic;

  useEffect(() => {
    if (portfolio?.type === "money") {
      const money = portfolio.funds.reduce((p, n) => p + n.money, 0);
      setAmount(money);
    }
  }, [portfolio?.funds]);

  useEffect(() => {
    setOperations(
      portfolio?.funds?.map((f) => ({
        fundId: f.id,
        amount: f.percentage * amount,
        kind: OperationKind.BUY,
        interval: {
          amount: 0,
          kind: "years",
        },
      })) ?? []
    );
  }, [portfolio?.funds, amount]);

  const setAmount = (val: number) =>
    onChangeRequest(SimulationKind.PIC, set(pic, "initialInvestment", val));
  const setShouldDeflate = (val: boolean) =>
    onChangeRequest(SimulationKind.PIC, set(pic, "showInflation", val));

  const fundIds = portfolio?.funds?.map((d) => d.id);
  const { data, isLoading } = useQuery([fundIds], async () => {
    return Promise.all([
      ...(fundIds?.map((d) => getFundData(d)) ?? []),
      getFundData(DEF_FUND_ID),
    ]) as Promise<FundHistory[]>;
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      const startDates =
        data?.map((d) => DateTime.fromSeconds(d.startDate)) ?? [];
      const endDates = data?.map((d) => DateTime.fromSeconds(d.endDate)) ?? [];

      const startDate = DateTime.max(...startDates, rollingStartDate);
      const endDate = DateTime.min(...endDates);
      setStartDate(startDate);
      setMinStartDate(startDate);
      setEndDate(endDate);
      setMaxEndDate(endDate);
      setWindow({ start: startDate, end: endDate });
    }
  }, [data]);

  useEffect(() => {
    setRollingStartDate(DateTime.fromSeconds(pic.start));
  }, [pic.start]);

  useEffect(() => {
    setMinStartDate(rollingStartDate);
    setStartDate(rollingStartDate);

    setWindow({
      ...window,
      start: rollingStartDate,
    });
  }, [rollingStartDate]);

  // useEffect(() => {
  //   console.log("nExt rolling start", rollingStartDate);
  // }, [rollingStartDate]);

  const spotResults: SpotResult[] = useMemo(() => {
    return additionalsLineData.map((d, index) => {
      const cols = d.columns - 1;
      const amount = d.get(AMOUNT, cols);
      const invested = d.get(INVESTED, cols);
      return {
        absolute: amount / invested - 1,
        yearly: yearlyAdditionals?.[index],
      };
    });
  }, [additionalsLineData, yearlyAdditionals]);

  useEffect(() => {
    const someResult = first(spotResults);
    if (someResult != null && someResult.yearly == null && lineData != null) {
      const dates = lineData.getRow(DATES);
      const initial = lineData.get(AMOUNT, 0);
      const numOfDates = dates.length;
      const requests = additionalsLineData.reduce(
        (prev: GoalRequest[], additional, index) => {
          let money = clone(last(prev)?.money ?? new Array(numOfDates).fill(0));
          if (index == 0) {
            money[0] = initial;
          } else {
            const spotOperation = spotOperations[index - 1];
            if (spotOperation != null) {
              const spotOffset = Math.ceil(
                DateTime.fromSeconds(spotOperation.unix).diff(
                  window.start,
                  "days"
                ).days
              );
              money[spotOffset] = spotOperation.money;
            }
          }
          return [
            ...prev,
            {
              money,
              investmentDate: dates,
              exit: additional.get(AMOUNT, additional.columns - 1),
              endInvestment: additional.get(DATES, additional.columns - 1),
            },
          ];
        },
        []
      );
      goalSeek
        .multiple({
          requests,
        })
        .then((res) => {
          setYearlyAdditionals(res.responses.map((d) => d.value));
        });
    }
  }, [spotResults]);

  async function runPicRolling() {
    if (data != null) {
      console.time("runPicRolling");
      console.log("runPicRolling");

      let r =
        rollingStartDate.day === 1
          ? rollingStartDate.startOf("day")
          : rollingStartDate.plus({ month: 1 }).startOf("month");
      console.log("Rolling start date is", r.toISO());

      return rolling({
        relativeOperations: operations,
        rollingStartDate: r,
        interval: { [intervalKind]: interval },
        duration: { [durationKind]: duration },
        data,
        deflateFundId: shouldDeflate ? DEF_FUND_ID : undefined,
        onlyBounds: true,
        fixedRate,
        annualCommission,
      })
        .then(setRollingData)
        .catch((err) => {
          console.error("[Rolling] Error...", err);
          console.time("runPicRolling");
        });
    }
  }

  async function runPicLine() {
    if (data != null && startDate < endDate) {
      console.time("runPicLine");

      await worker
        .run({
          type: WorkerMessageType.RUN_PIC,
          payload: {
            ...convertRollingData(
              data,
              startDate.startOf("day"),
              endDate.startOf("day"),
              operations
            ),
            fixedRate,
            annualCommission,
            deflateFundId: shouldDeflate ? DEF_FUND_ID : undefined,
          },
        })
        .then((r) => {
          // console.log("[Line] Got resutl", r);
          setLineData(convertResult(r));
          setFundsData(convertSingleFundsResult(r, amount));
        })
        .catch((err) => {
          console.error("[Line] Error!", err);
        });
      console.timeEnd("runPicLine");
    }
  }

  async function runPicAdditionals() {
    if (data != null && window.start < window.end) {
      const req = v4();
      isPendingAdditionals.current = req;

      const end = window.end.plus({ day: 1 }).startOf("day");

      const rollingData = convertRollingData(
        data,
        window.start.startOf("day"),
        end <= endDate ? end : endDate,
        operations
      );

      await additionals({
        ...rollingData,
        spotOperations,
        funds: portfolio?.funds ?? [],
        fixedRate,
        annualCommission,
        deflateFundId: shouldDeflate ? DEF_FUND_ID : undefined,
      })
        .then((r) => {
          if (isPendingAdditionals.current === req) {
            setYearlyAdditionals([]);
            setAdditionalsLineData(r);
          }
        })
        .catch((err) => {
          console.error("[Line] Error!", err);
        });
    }
  }

  async function runPicWindow({
    data,
    window,
    operations,
    shouldFixQuotes,
    fundsData,
    startDate,
    endDate,
    shouldDeflate,
    annualCommission,
    fixedRate,
    amount
  }: {
    data?: FundHistory[];
    window: DateWindow;
    operations: RelativeOperation[];
    shouldFixQuotes: boolean;
    fundsData: Matrix[] | null;
    startDate: DateTime;
    endDate: DateTime;
    fixedRate?: number;
    amount: number;
    annualCommission?: number;
    shouldDeflate: boolean;
  }) {
    console.log("Run Pic window");

    if (data != null && window.start < window.end && startDate < endDate) {
      const end = window.end;
      console.time("runPicWindow");

      // If should fix quotes, we have to change the relative operations.
      let windowOperations = operations;
      if (shouldFixQuotes && fundsData != null) {
        const index = Math.floor(window.start.diff(startDate, "days").days);
        const amounts = compact(fundsData.map((d) => d.get(AMOUNT, index)));
        const totalAmount = amounts.reduce((p, n) => p + n, 0);
        if (shouldFixQuotes && fundsData != null) {
          const index = Math.ceil(window.start.diff(startDate, "days").days);
          const amounts = compact(fundsData.map((d) => d.get(AMOUNT, index)));
          windowOperations = operations.map((d, i) => ({
            ...d,
            amount: d.amount * (amounts[i] / fundsData[i].get(AMOUNT, 0)),
            
          }));
          const totalAmount = compact(windowOperations.map((w) => w.amount)).reduce((p, n) => p + n, 0);
          windowOperations.forEach((e) => console.log(e.amount))
          windowOperations = windowOperations.map((d, i) => ({
            ...d,
            amount: d.amount * amount / totalAmount,
          }));
        }
      }

      await worker
        .run({
          type: WorkerMessageType.RUN_PIC,
          payload: {
            ...convertRollingData(
              data,
              window.start.startOf("day"),
              (end <= endDate ? end : endDate).plus({ day: 1 }).startOf("day"),
              windowOperations
            ),
            fixedRate,
            annualCommission,
            deflateFundId: shouldDeflate ? DEF_FUND_ID : undefined,
          },
        })
        .then((r) => {
          // console.log("[Window] Got resutl", r);
          const data = convertResult(r);
          console.log(data)
          const fundsData = convertSingleFundsResult(r, amount);
          setAbsolute(getAbsoluteYield(data));
          setYearly(getYearlyYield(data));
          setFinalAmount(getLastAmount(data));
          setfinalInvestedAmount(getLastInvestedAmount(data));
          setOverview(calculatePicOverview(shouldDeflate, data));
          setLegendItems(getLegendItems(isRealPic, data, fundsData, portfolio));
        })
        .catch((err) => {
          console.error("[Line] Error!", err);
        });
      console.timeEnd("runPicWindow");
    }
  }

  const runPicWindowThrottled = useCallback(debounce(runPicWindow, 100), [
    portfolio,
  ]);
  const runPicAdditionalsThrottled = debounce(runPicAdditionals, 10);

  useEffect(() => {
    runPicRolling();
  }, [
    data,
    rollingStartDate,
    operations,
    duration,
    durationKind,
    fixedRate,
    interval,
    intervalKind,
    shouldDeflate,
    annualCommission,
  ]);

  useEffect(() => {
    runPicLine();
  }, [
    data,
    startDate,
    endDate,
    operations,
    amount,
    shouldDeflate,
    fixedRate,
    annualCommission,
    showSingleFunds,
  ]);

  // Rolling giornalerio con data puntuale di inizio ( altrimenti sempre 1 del mese )
  // Nel grafico alinea, partire dalla data di inizo simulazione
  // Aggiungere Reale nelle label dei Rendimenti nel caso inflazione = True
  // (Radice 365-esima di 1 + Tasso Annuale ( 0,08 = 8% )) - 1
  useEffect(() => {
    console.log("runPicWindowThrottled INPUT:::::", data,
    fundsData,
    startDate,
    endDate,
    window,
    operations,
    amount)
    runPicWindowThrottled({
      data,
      fundsData,
      startDate,
      endDate,
      window,
      operations,
      amount,
      shouldDeflate,
      fixedRate,
      shouldFixQuotes,
      annualCommission,
    });
  }, [
    data,
    fundsData,
    startDate,
    endDate,
    window,
    operations,
    amount,
    shouldDeflate,
    fixedRate,
    shouldFixQuotes,
    annualCommission,
  ]);

  useEffect(() => {
    runPicAdditionalsThrottled();
  }, [
    data,
    spotOperations,
    window,
    operations,
    amount,
    shouldDeflate,
    fixedRate,
    shouldFixQuotes,
    annualCommission,
  ]);

  const earnTable = useEarnTable(rollingData);
  const [deferredWindow] = useDebounce(window, 500);

  const charts = usePicCharts(
    portfolio,
    rollingData,
    lineData,
    fundsData,
    additionalsLineData,
    deferredWindow,
    isRealPic,
    showInterestsCompare,
    interestsCompareFundId
  );
  const toggleDeflate = () => setShouldDeflate(!shouldDeflate);
  const funds = data ?? [];

  const zoomIn = () => {
    setStartDate(window.start);
    setEndDate(window.end);
  };
  const zoomOut = () => {
    setWindow({
      start: minStartDate,
      end: maxEndDate,
    });
    setStartDate(minStartDate);
    setEndDate(maxEndDate);
  };

  const isZoomed =
    !startDate.equals(minStartDate) || !endDate.equals(maxEndDate);

  const focusSimulation = (index: number) => {
    if (index < rollingData.length) {
      const simulation = rollingData[index];
      const start = DateTime.fromSeconds(simulation.get(DATES, 0));
      const end = DateTime.fromSeconds(
        simulation.get(DATES, simulation.columns - 1)
      );
      setWindow({ start, end });
    }
  };
  // const overview = usePicOverview(shouldDeflate, lin);

  return {
    startDate,
    legendItems,
    endDate,
    setStartDate,
    setEndDate,
    shouldDeflate,
    duration,
    setDurationAmount,
    amount,
    absolute,
    window,
    rollingData,
    yearly,
    finalAmount,
    setAmount,
    setIntervalKind,
    setIntervalAmount,
    setWindow,
    intervalValue,
    focusSimulation,
    charts,
    minStartDate,
    maxEndDate,
    earnTable,
    funds,
    spotResults,
    zoomIn,
    zoomOut,
    overview,
    isLoading,
    isZoomed,
    toggleDeflate,
    finalInvestedAmount,
    fixedRate,
    annualCommission,
  };
}

export interface RealPicOverview {
  shouldDeflate: boolean;
  fixedRate: { amount: number; absolute: number; yearly: number };
  invested: { amount: number; absolute: number; yearly: number };
}

function findIndexes<T>(arr: T[], fn: (val: T) => boolean) {
  var indexes = [],
    i;
  for (i = 0; i < arr.length; i++) {
    if (fn(arr[i])) {
      indexes.push(i);
    }
  }
  return indexes;
}
