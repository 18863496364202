import { config } from "config";
import { authController } from "contorller/auth/controller";
import { DateTime } from "luxon";
import DownloadWorker from "../../worker_test.js?worker";
import { db, FundHistory } from "./db";

const MIN_REFRESH_HOURS_INTERVAL = 1;
const LOG_LEVEL = false;

const worker: Worker = new DownloadWorker();

export async function downloadFund(id: string) {
  let auther = authController().getAuther();
  let headers = await auther.getHeaderToken();

  const baseUrl = config().URL;
  const message = {
    headers,
    baseUrl,
    fundId: id,
  };

  worker.postMessage(message);
}

export async function getFundData(
  id: string,
  proxy: boolean = false
): Promise<FundHistory> {
  const prefix = `[FundHistory-${id.split("-")[0]}]`;
  if (LOG_LEVEL) console.log(`${prefix} Get data`);

  const fundHistory = await db.histories.get(id);
  if (fundHistory != null) {
    if (LOG_LEVEL) console.log(`${prefix} Found in storage...`);

    const age = Math.abs(
      DateTime.fromSeconds(fundHistory.lastUpdate).diffNow("hours").hours
    );
    const shouldRefreshData = age > MIN_REFRESH_HOURS_INTERVAL;
    if (shouldRefreshData) {
      if (LOG_LEVEL) console.log(`${prefix} Refreshing data...`);
      downloadFund(id);
    } else {
      if (LOG_LEVEL)
        console.log(
          `${prefix} Not refreshing because the cached version age is ${age}`
        );
    }

    return fundHistory;
  } else {
    if (LOG_LEVEL)
      console.log(`${prefix} Not found in storage, waiting to download it`);
  }

  return new Promise((resolve) => {
    worker.addEventListener("message", (ev: MessageEvent<FundHistory>) => {
      if (ev.data.fundId === id) {
        resolve(ev.data);
      }
    });
    downloadFund(id);
    return null;
  });
}
