import { useQuery } from "@tanstack/react-query";
import {
  SimulationKind,
  useSimulationContext,
} from "components/organismes/WindowSimulation/useSimulationContext";
import { RootState } from "contorller";
import { FundHistory } from "contorller/history/db";
import { getFundData } from "contorller/history/downloader";
import { PortfolioWithFunction } from "contorller/portfolio/types";
import { compact, isEmpty, set } from "lodash";
import { set as set_fp } from "lodash/fp";
import { DateTime } from "luxon";
import Matrix from "ml-matrix";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MetricLineResponse, movingAverage, movingMax, movingMin } from "simulator/metrics";
import { PromiseWorker } from "simulator/promise";
import { convertRollingData } from "simulator/rolling";
import {
  AMOUNT,
  DATES,
  FUNCTION_DATA,
  FUNCTION_FILLER,
  OperationKind,
  RelativeOperation,
  WorkerMessageType,
  convertResult,
  convertSingleFundsResult
} from "simulator/types";
import { AlertGoalType } from "types/alert";
import { useDebounce } from "use-debounce";
import SimulatorWorker from "../worker.js?worker";
import { FundLegendItem } from "./pic/legendItems";
import { usePicChartsCompare } from "./picCompare/charts";
import { useGetLegendItemsCompare } from "./picCompare/legendItems";

const DEF_FUND_ID = "855c8138-3014-5fe3-8923-080edce2bb98";
const worker = new PromiseWorker(new SimulatorWorker());

interface DateWindow {
  start: DateTime;
  end: DateTime;
}

export function usePicRunnerCompare(isRealPic: boolean, portfolios?: PortfolioWithFunction[]) {
  const [legendItems, setLegendItems] = useState<FundLegendItem[]>([]);
  const [window, setWindow] = useState<DateWindow>({
    start: DateTime.local(),
    end: DateTime.local(),
  });
  const [rollingStartDate, setRollingStartDate] = useState<DateTime>(
    DateTime.local()
  );

  const [minStartDate, setMinStartDate] = useState<DateTime>(DateTime.local());
  const [startDate, setStartDate] = useState<DateTime>(DateTime.local());
  const [endDate, setEndDate] = useState<DateTime>(DateTime.local());
  const [maxEndDate, setMaxEndDate] = useState<DateTime>(DateTime.local());
  const [multiOperations, setMultiOperations] = useState<Array<RelativeOperation[]>>([]);
  // const [rollingData, setRollingData] = useState<Matrix[]>([]);
  const [fundsData, setFundsData] = useState<Array<Matrix[]> | null>(null);
  const [linesData, setLinesData] = useState<Array<Matrix> | null>([]);
  const [absolute, setAbsolute] = useState<number>(0);
  const [finalAmount, setFinalAmount] = useState<number>(0);
  const [finalInvestedAmount, setfinalInvestedAmount] = useState<number>(0);
  const [yearly, setYearly] = useState<number>(0);
  const { pic, onChangeRequest } = useSimulationContext();
  const getLegendItems = useGetLegendItemsCompare();
  const shouldFixQuotes = useSelector<RootState, boolean>(
    (s) => s.settings.shouldFixQuotes
  );
  const showSingleFunds = useSelector<RootState, boolean>(
    (s) => s.pic.graphOptions.showSingleFunds
  );

  const amount = pic.initialInvestment;
  const shouldDeflate = pic.showInflation ?? false;
  const showInterestsCompare = pic.showInterestsCompare ?? false;
  const interestsCompareFundId = pic.interestsCompareFundId;
  const { fixedRate, annualCommission } = pic;
  
  useEffect(() => {
    setMultiOperations(
      portfolios?.map(ptf => ptf.funds?.map((f) => ({
        fundId: f.id,
        amount: f.percentage * amount,
        kind: OperationKind.BUY,
        interval: {
          amount: 0,
          kind: "years",
        },
      }))) ?? []
    );
  }, [portfolios, amount]);

  const setAmount = (val: number) =>
    onChangeRequest(SimulationKind.PIC, set(pic, "initialInvestment", val));
  const setShouldDeflate = (val: boolean) =>
    onChangeRequest(SimulationKind.PIC, set(pic, "showInflation", val));
  const fundIds = [...new Set(portfolios?.flatMap((ptf) => ptf.funds.map((d) => d.id)))];
  const { data, isLoading } = useQuery([fundIds], async () => {
    return Promise.all([
      ...(fundIds?.map((d) => getFundData(d)) ?? []),
      getFundData(DEF_FUND_ID),
    ]) as Promise<FundHistory[]>;
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      const startDates =
        data?.map((d) => DateTime.fromSeconds(d.startDate)) ?? [];
      const endDates = data?.map((d) => DateTime.fromSeconds(d.endDate)) ?? [];

      const startDate = DateTime.max(...startDates, rollingStartDate);
      const endDate = DateTime.min(...endDates);
      setStartDate(startDate);
      setMinStartDate(startDate);
      setEndDate(endDate);
      setMaxEndDate(endDate);
      setWindow({ start: startDate, end: endDate });
    }
  }, [data]);

  useEffect(() => {
    setRollingStartDate(DateTime.fromSeconds(pic.start));
  }, [pic.start]);

  useEffect(() => {
    setMinStartDate(rollingStartDate);
    setStartDate(rollingStartDate);

    setWindow({
      ...window,
      start: rollingStartDate,
    });
  }, [rollingStartDate]);

  async function runPicLine(index: number) {
    if (data != null && startDate < endDate) {
      console.time(`runPicLine on index: ${index}`);

      await worker
        .run({
          type: WorkerMessageType.RUN_PIC,
          payload: {
            ...convertRollingData(
              data
              .filter(f => portfolios?.[index].funds.map(f=>f.id).includes(f.fundId) || f.fundId === DEF_FUND_ID) // pick only ptf funds
              .filter((fund, i, self) => self.findIndex(fund2 => (fund.fundId === fund2.fundId)) === i), // remove duplicates
              startDate.startOf("day"),
              endDate.startOf("day"),
              multiOperations[index]
            ),
            fixedRate,
            annualCommission,
            deflateFundId: shouldDeflate ? DEF_FUND_ID : undefined,
          },
        })
        .then((r) => {
          const matrixData = convertResult(r);
          if (portfolios?.[index].functionType != null && portfolios?.[index].functionDaysPeriod != null) {
            let metricResp : MetricLineResponse;
            switch (portfolios?.[index].functionType) {
              case AlertGoalType.DELTA_MOVING_AVG: 
                metricResp = movingAverage(matrixData.getRow(AMOUNT),portfolios?.[index].functionDaysPeriod ?? 365);
                break;
              case AlertGoalType.DELTA_MAX: 
                metricResp = movingMax(matrixData.getRow(AMOUNT),portfolios?.[index].functionDaysPeriod ?? 365);
                break;
              case AlertGoalType.DELTA_MIN: 
                metricResp = movingMin(matrixData.getRow(AMOUNT),portfolios?.[index].functionDaysPeriod ?? 365);
                break;
              default:
                metricResp = movingAverage(matrixData.getRow(AMOUNT),portfolios?.[index].functionDaysPeriod ?? 365);
            }
            if (matrixData.rows <= DATES+1) {
              matrixData.addRow(matrixData.rows, metricResp.result);
              matrixData.addRow(matrixData.rows, metricResp.filler);
            } else {
              matrixData.setRow(FUNCTION_DATA, metricResp.result);
              matrixData.setRow(FUNCTION_FILLER, metricResp.filler);
            }
          } 
          setLinesData(set_fp(index, matrixData));
          setFundsData(set_fp(index, convertSingleFundsResult(r, amount)));
        })
        .catch((err) => {
          console.error("[Line] Error!", err);
        });
      console.timeEnd(`runPicLine on index: ${index}`);
    }
  }

  async function runPicWindow(ptfIndex: number) : Promise<Matrix|null> {
    let matrixData= null;
    if (data != null && window.start < window.end && startDate < endDate) {
      const end = window.end;
      console.time(`runPicWindow on index: ${ptfIndex}`);

      // If should fix quotes, we have to change the relative operations.
      let windowOperations = multiOperations[ptfIndex];
      if (shouldFixQuotes && fundsData?.[ptfIndex] != null) {
        const index = Math.floor(window.start.diff(startDate, "days").days);
        const amounts = compact(fundsData[ptfIndex].map((d) => d.get(AMOUNT, index)));
        const totalAmount = amounts.reduce((p, n) => p + n, 0);
        if (shouldFixQuotes && fundsData != null) {
          const index = Math.ceil(window.start.diff(startDate, "days").days);
          const amounts = compact(fundsData[ptfIndex].map((d) => d.get(AMOUNT, index)));
          windowOperations = multiOperations[ptfIndex].map((d, i) => ({
            ...d,
            amount: d.amount * (amounts[i] / fundsData[ptfIndex][i].get(AMOUNT, 0)),
            
          }));
          const totalAmount = compact(windowOperations.map((w) => w.amount)).reduce((p, n) => p + n, 0);
          // biome-ignore lint/complexity/noForEach: <explanation>
          //windowOperations.forEach((e) => console.log(e.amount))
          windowOperations = windowOperations.map((d, i) => ({
            ...d,
            amount: d.amount * amount / totalAmount,
          }));
        }
      }
      try {

        await worker
          .run({
            type: WorkerMessageType.RUN_PIC,
            payload: {
              ...convertRollingData(
                data
                .filter(f => portfolios?.[ptfIndex].funds.map(f=>f.id).includes(f.fundId) || f.fundId === DEF_FUND_ID) // pick only ptf funds
                .filter((fund, i, self) => self.findIndex(fund2 => (fund.fundId === fund2.fundId)) === i), // remove duplicates
                window.start.startOf("day"),
                (end <= endDate ? end : endDate).plus({ day: 1 }).startOf("day"),
                windowOperations
              ),
              fixedRate,
              annualCommission,
              deflateFundId: shouldDeflate ? DEF_FUND_ID : undefined,
            },
          })
          .then((r) => {
            matrixData = convertResult(r);
            if (portfolios?.[ptfIndex].functionType != null && portfolios?.[ptfIndex].functionDaysPeriod != null) {
              let metricResp : MetricLineResponse;
              switch (portfolios?.[ptfIndex].functionType) {
                case AlertGoalType.DELTA_MOVING_AVG: 
                  metricResp = movingAverage(matrixData.getRow(AMOUNT),portfolios?.[ptfIndex].functionDaysPeriod ?? 365);
                  break;
                case AlertGoalType.DELTA_MAX: 
                  metricResp = movingMax(matrixData.getRow(AMOUNT),portfolios?.[ptfIndex].functionDaysPeriod ?? 365);
                  break;
                case AlertGoalType.DELTA_MIN: 
                  metricResp = movingMin(matrixData.getRow(AMOUNT),portfolios?.[ptfIndex].functionDaysPeriod ?? 365);
                  break;
                default:
                  metricResp = movingAverage(matrixData.getRow(AMOUNT),portfolios?.[ptfIndex].functionDaysPeriod ?? 365);
              }
              if (matrixData.rows <= DATES+1) {
                matrixData.addRow(matrixData.rows, metricResp.result);
                matrixData.addRow(matrixData.rows, metricResp.filler);
              } else {
                matrixData.setRow(FUNCTION_DATA, metricResp.result);
                matrixData.setRow(FUNCTION_FILLER, metricResp.filler);
              }
            }
          })
          .catch((err) => {
            console.warn("[Line] Error!", err);
          });
        console.timeEnd(`runPicWindow on index: ${ptfIndex}`);
      } catch (err) {
        console.warn(err);
      }
    }
    return matrixData
  }


  useEffect(() => {
    (async () => {
      await Promise.all([...portfolios?.map((_,i) => runPicLine(i)) ?? []]).then(() => console.log("All picLine functions finished!"));
    })();
  }, [
    data,
    startDate,
    endDate,
    multiOperations,
    amount,
    shouldDeflate,
    fixedRate,
    annualCommission,
    showSingleFunds,
    portfolios
  ]);

  
  // const earnTable = useEarnTable(rollingData);
  const [deferredWindow] = useDebounce(window, 500);
  
  // Rolling giornalerio con data puntuale di inizio ( altrimenti sempre 1 del mese )
  // Nel grafico alinea, partire dalla data di inizo simulazione
  // Aggiungere Reale nelle label dei Rendimenti nel caso inflazione = True
  // (Radice 365-esima di 1 + Tasso Annuale ( 0,08 = 8% )) - 1
  useEffect(() => {
    (async () => {
      await Promise.all([...portfolios?.map((_,i) => runPicWindow(i)) ?? []]).then((results) => setLegendItems(getLegendItems(isRealPic, compact(results), portfolios)));
    })();
  }, [
    data,
    fundsData,
    startDate,
    endDate,
    deferredWindow,
    multiOperations,
    amount,
    shouldDeflate,
    fixedRate,
    shouldFixQuotes,
    annualCommission,
    portfolios,
  ]);

  const charts = usePicChartsCompare(
    portfolios,
    linesData,
    // deferredWindow,
    isRealPic,
    showInterestsCompare,
    interestsCompareFundId
  );
  const toggleDeflate = () => setShouldDeflate(!shouldDeflate);
  const funds = data ?? [];

  const zoomIn = () => {
    setStartDate(window.start);
    setEndDate(window.end);
  };
  const zoomOut = () => {
    setWindow({
      start: minStartDate,
      end: maxEndDate,
    });
    setStartDate(minStartDate);
    setEndDate(maxEndDate);
  };

  const isZoomed =
    !startDate.equals(minStartDate) || !endDate.equals(maxEndDate);

  // const focusSimulation = (index: number) => {
  //   if (index < rollingData.length) {
  //     const simulation = rollingData[index];
  //     const start = DateTime.fromSeconds(simulation.get(DATES, 0));
  //     const end = DateTime.fromSeconds(
  //       simulation.get(DATES, simulation.columns - 1)
  //     );
  //     setWindow({ start, end });
  //   }
  // };
  // const overview = usePicOverview(shouldDeflate, lin);

  return {
    startDate,
    legendItems,
    endDate,
    setStartDate,
    setEndDate,
    shouldDeflate,
    // duration,
    // setDurationAmount,
    amount,
    absolute,
    window,
    // rollingData,
    yearly,
    finalAmount,
    setAmount,
    // setIntervalKind,
    // setIntervalAmount,
    setWindow,
    // intervalValue,
    // focusSimulation,
    charts,
    minStartDate,
    maxEndDate,
    // earnTable,
    funds,
    // spotResults,
    zoomIn,
    zoomOut,
    // overview,
    isLoading,
    isZoomed,
    toggleDeflate,
    finalInvestedAmount,
    fixedRate,
    annualCommission,
  };
}

export interface RealPicOverview {
  shouldDeflate: boolean;
  fixedRate: { amount: number; absolute: number; yearly: number };
  invested: { amount: number; absolute: number; yearly: number };
}
