import { unixToDate } from "contorller/utils";
import { PICResponse } from "../types";
import { calcPic, myFormatDate } from "./fx";



export function Zoom(response:PICResponse[], start:Date, end:Date): PICResponse[] {

    let s = myFormatDate(start)
    let e = myFormatDate(end)
    let starts = response.map<number>(f => {

        const index = f.snaps.findIndex((elm) => myFormatDate(unixToDate(elm.unix)) === s)
        if(index === -1) return 0
        return index
    }) 
    let ends = response.map<number>(f => {
        
        const i = f.snaps.findIndex((elm)=>myFormatDate(unixToDate(elm.unix)) === e)

        if (i === -1)
            return f.snaps.length-1

        return i 
    }) 


    
    let spliced = response.map((resp, index)=> {
        return {
            ...resp,
            snaps: calcPic( resp.request, [...resp.snaps.slice(starts[index], ends[index] + 1)],  starts[index]%resp.request.rebalance).snaps
        }
    })
    return spliced

}