import { compact } from "lodash";

export interface MetricLineResponse {
    result: number[];
    filler: number[];
}
export function movingAverage(data: number[], daysWindow: number): MetricLineResponse {
    const result :MetricLineResponse = {result: new Array(data.length).fill(null), filler: new Array(data.length).fill(null)}
    for (let i = daysWindow; i < data.length; i++) {
        result.result[i] = data.slice(i-daysWindow, i).reduce((prev, next) => prev + next, 0) / daysWindow
    }
    const step = (compact(result.result)[0] - data[0])/daysWindow
    for (let i = 0; i < daysWindow; i++) {
        result.filler[i]=data[0] + (i*step)
    }
    return result
}

export function movingMax(data: number[], daysWindow: number): MetricLineResponse {
    const result :MetricLineResponse = {result: new Array(data.length).fill(null), filler: new Array(data.length).fill(null)}
    for (let i = daysWindow; i < data.length; i++) {
        result.result[i] = Math.max(...data.slice(i-daysWindow, i))
    }
    result.filler = result.filler.fill(compact(result.result)[0], 0, daysWindow)
    return result
}

export function movingMin(data: number[], daysWindow: number): MetricLineResponse {
    const result :MetricLineResponse = {result: new Array(data.length).fill(null), filler: new Array(data.length).fill(null)}
    for (let i = daysWindow; i < data.length; i++) {
        result.result[i] = Math.min(...data.slice(i-daysWindow, i))
    }
    result.filler = result.filler.fill(compact(result.result)[0], 0, daysWindow)
    return result
}