export interface RawAPIRequest<Vars = {}, Data = {}> {
  baseUrl: string;
  endpoint: APIEndpoint<Vars>;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  dataKey?: string;
  legacy?: boolean;
  body?: Vars;
  json?: boolean;
  headers?: {};
}

type APIEndpoint<Vars> = string | ((vars: Vars) => string);

export async function runRawEmpirichQuery<T, Vars = {}>(
  request: RawAPIRequest<Vars, T>
): Promise<T> {
  return fetch(request.baseUrl + request.endpoint, {
    headers: request.headers,
    method: request.method ?? "GET",
    body: request.body != null ? JSON.stringify(request.body) : null,
  }).then(async (res) => {
    if (request.json === false) {
      return res.text();
    } else {
      const response = await res.json();
      if (request.legacy === true) {
        return response;
      }
      return request.dataKey != null
        ? response.payload[request.dataKey]
        : response.payload;
    }
  });
}
