import { Sum } from "contorller";
import { PICRequest, PICResponse, Snap } from "contorller/simulations/types";
import {
  absoluteEarge,
  annualEarge,
  cutSnaps,
  getDays,
} from "contorller/utils";

interface fund {
  weight: number;
  quote: number;
}

export const calcPic = (
  request: PICRequest,
  closes: { closes: number[]; unix: number }[],
  rebalanceStart?: number
): PICResponse => {
  //FIXME: da capire perché il close possa essere zero, il closes sono valori di chiusure nel tempo unix rappresenta il momento del close
  // if (closes.length === 0) {
  // return {
  // request: { ...request },
  // name: request.name,
  // id: "",
  // annual: 0,
  // absolute:0,
  // snaps:[]
  // }
  // }

  const internalFunds: fund[] = [];
  request.funds.forEach((v, index) => {
    internalFunds.push({
      weight: v.weight,
      quote: (request.initialInvestment * v.weight) / closes[0].closes[index],
    });
  });

  const additionalQuotes = request.investments.map(({ money, unix, fund }) => {
    const res = closes.find((d) => d.unix === unix);
    if (res != null) {
      return request.funds.map((v, idx) => {
        if (fund != null) {
          return fund === v.id ? money / res.closes[idx] : 0;
        } else {
          return (v.weight * money) / res.closes[idx];
        }
      });
    } else {
      console.warn("Cannot find closes with that given unix timestamp");
    }

    return request.funds.map(() => 0);
  });

  let rebalanceIndex = rebalanceStart || 0;
  const snaps = closes.map<Snap>((v: { closes: number[]; unix: number }) => {
    if (request.rebalance !== 0) {
      rebalanceIndex++;
      if (rebalanceIndex === request.rebalance) {
        rebalanceIndex = 0;
        let sum = Sum(internalFunds.map((e, i) => e.quote * v.closes[i]));
        internalFunds.forEach((fund, index) => {
          fund.quote = (sum * fund.weight) / v.closes[index];
        });
      }
    }

    const howManyAdditionals = request.investments.filter(
      (u) => v.unix >= u.unix
    ).length;
    const currentAdditionalQuotes = additionalQuotes
      .slice(0, howManyAdditionals)
      .reduce(
        (prev, next) => {
          return prev.map((v, idx) => v + next[idx]);
        },
        request.funds.map(() => 0)
      );

    return {
      funds: internalFunds.map<number>(
        (f, i) => (f.quote + currentAdditionalQuotes[i]) * v.closes[i]
      ),
      closes: v.closes,
      unix: v.unix,
      quotes: internalFunds.map((f, i) => f.quote + currentAdditionalQuotes[i]),
      investedMoney:
        request.initialInvestment +
        request.investments
          .slice(0, howManyAdditionals)
          .reduce((p, n) => p + n.money, 0),
      vmcIn: [0],
      vmcOut: [0],
      moneyExchange: 0,
    } as Snap;
  });

  const ctu = Sum(snaps[snaps.length - 1].funds);
  const cti = Sum(snaps[0].funds);
  let days = getDays(
    new Date(snaps[0].unix * 1000),
    new Date(snaps[snaps.length - 1].unix * 1000)
  );

  // Fix for non precise years.
  if (days === 366) {
    days = 365;
  }

  const annual = annualEarge(ctu, cti, days);
  const absolute = absoluteEarge(ctu, cti);

  const result = {
    request: request,
    snaps: snaps,
    annual,
    absolute,
    id: "",
    name: request.name,
  };
  return result;
};
//FIXME: da cancellare
export function myFormatDate(date: Date): string {
  date.setHours(15, 30);
  return date.toISOString().split("T")[0];
}

export interface PicEarnResponse {
  id: string;
  annual: number;
  absolute: number;
}

export function EarningMapSingleFund(
  response: PICResponse,
  start?: Date,
  end?: Date
): PicEarnResponse[] {
  return response.request.funds.map<PicEarnResponse>((f, index) => {
    let snap = cutSnaps(response.snaps, start, end);

    return {
      id: f.id,
      annual: 0,
      absolute: absoluteEarge(
        snap[snap.length - 1].funds[index],
        snap[0].funds[index]
      ),
    };
  });
}
