import { FundHistory } from "contorller/history/db";
import { PortfolioFund } from "contorller/portfolio/types";
import { SpotOperation } from "contorller/simulations/PAC/redux";
import { range } from "lodash";
import { DateTime } from "luxon";
import Matrix from "ml-matrix";
import { scaleFundData } from "./fund";
import { PromiseWorker } from "./promise";
import {
  convertResult,
  DATES,
  Operation,
  OperationKind,
  WorkerMessageType,
} from "./types";
import SimulatorWorker from "./worker.js?worker";

const worker = new PromiseWorker(new SimulatorWorker());

export interface AdditionalsOptions {
  data: FundHistory[];
  funds: PortfolioFund[];
  operations: Operation[];
  spotOperations: SpotOperation[];
  deflateFundId?: string;
  fixedRate?: number;
  annualCommission?: number;
}

export async function additionals(
  options: AdditionalsOptions
): Promise<Matrix[]> {
  const additionalOperations = options.spotOperations.map((spotOperation) => {
    const kind =
      spotOperation.money > 0 ? OperationKind.BUY : OperationKind.SELL;
    const date = new Date(spotOperation.unix * 1000);
    const operations: Operation[] =
      spotOperation.fundId == null
        ? options.funds.map((fund) => ({
            amount: Math.abs(spotOperation.money * fund.percentage),
            date,
            kind,
            fundId: fund.id,
          }))
        : [
            {
              amount: Math.abs(spotOperation.money),
              date,
              kind,
              fundId: spotOperation.fundId,
            },
          ];
    return operations;
  });

  const result = await Promise.all(
    [options.operations, ...additionalOperations].map(async (operations) => {
      const someDate = operations[0].date;
      const scaleDate = someDate != null ? DateTime.fromJSDate(someDate) : null;
      return worker
        .run({
          type: WorkerMessageType.RUN_PIC,
          payload: {
            ...options,
            data: options.data.map((fund) =>
              scaleFundData(
                fund,
                scaleDate ?? DateTime.fromSeconds(fund.startDate),
                DateTime.fromSeconds(fund.endDate)
              )
            ),
            operations,
          },
        })
        .then(convertResult);
    })
  );

  return result.map((value, index) => {
    return result.slice(0, index).reduce((prev, next) => {
      const bigger = next.columns > prev.columns ? next : prev;
      const smaller = next.columns > prev.columns ? prev : next;
      const differenceColumns = bigger.columns - smaller.columns;
      console.log(bigger.columns, bigger.rows, smaller.columns, smaller.rows);
      const resized = bigger.selection(
        range(next.rows),
        range(differenceColumns, differenceColumns + smaller.columns)
      );

      console.log(resized.columns, resized.rows, smaller.columns, smaller.rows);

      const result = Matrix.add(smaller, resized);
      result.setRow(DATES, smaller.getRow(DATES));
      return result;
    }, value);
  });
}
