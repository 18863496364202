import { Sum } from "contorller";
import { placeholderNewPortfolio } from "./redux";
import { Portfolio } from "./types";

export const PORTFOLIO_PRECISION = 5;

export function fundName(id: string, portfolio?: Portfolio): string {
  if (portfolio)
    for (let i of portfolio.funds) {
      if (i.id === id) {
        return i.fund.name;
      }
    }

  throw new Error("non trovo questo fondo");
}

export interface PercentageError {
  error: boolean;
  percentageError: number;
  percentage: number;
}

export function isDefaultName(p: Portfolio) {
  return p.name === placeholderNewPortfolio.name || p.isDanika === true;
}

export function checkPercentagePortfolio(
  portfolio: Portfolio,
  ignoreError: boolean = false
): PercentageError {
  if (ignoreError) {
    return {
      error: false,
      percentage: 1,
      percentageError: 0,
    };
  }
  if (
    portfolio.type === "percentage" &&
    portfolio.funds != null &&
    portfolio.funds.length > 0
  ) {
    const percentages = portfolio.funds.map((e) => {
      //console.log("singola percentuale",  parseFloat(e.percentage.toFixed(3)))
      return parseFloat(e.percentage.toFixed(PORTFOLIO_PRECISION));
    });
    const p = Sum(percentages);
    console.log(
      Sum(percentages),
      percentages,
      p.toFixed(PORTFOLIO_PRECISION),
      (1).toFixed(PORTFOLIO_PRECISION)
    );
    return {
      error:
        p.toFixed(PORTFOLIO_PRECISION) !== (1).toFixed(PORTFOLIO_PRECISION),
      percentage: p,
      percentageError: 1 - p,
    };
  } else {
    return {
      error: false,
      percentage: 1,
      percentageError: 0,
    };
  }
}

export function roundPortfolioPercentage(value: number): number {
  return parseFloat(value.toFixed(PORTFOLIO_PRECISION));
}

export function percentageFundRebalance(portfolio: Portfolio): number[] {
  if (portfolio.type === "percentage") {
    let percentages = portfolio.funds.map((e) => e.percentage);

    //if (Sum(percentages) === 1) return percentages;

    const fixedPercentage = parseFloat(
      (1.0 / portfolio.funds.length).toFixed(4)
    );
    const totalPercentage = fixedPercentage * portfolio.funds.length;
    const evenPercentage =
      totalPercentage !== 1
        ? 1 - fixedPercentage * (portfolio.funds.length - 1)
        : fixedPercentage;

    return portfolio.funds.map((_, index) =>
      index === 0 ? evenPercentage : fixedPercentage
    );
  } else {
    return portfolio.funds.map((e) => e.percentage);
  }
}

export function sanitizePortfolioPercentages(p: Portfolio) {
  return {
    ...p,
    funds: p.funds.map((d) => ({
      ...d,
      percentage: parseFloat(d.percentage.toFixed(PORTFOLIO_PRECISION)),
    })),
  };
}

export function isUUID(id: string): boolean {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(id);
}