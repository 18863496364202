import { GuaranteeProposal } from "components/molecols/GuaranteeProposalEditor";
import { Portfolio } from "contorller/portfolio/types";
import { TPortfolio } from "contorller/tptf/types";

export const PTF_OBJ ="Ptf";
export const TPTF_OBJ ="Tptf";
export const PROTECTION_OBJ ="Protection";

export const SHARABLE_KEYS =[ PTF_OBJ, TPTF_OBJ, PROTECTION_OBJ ];

export type sharableObject =
| Portfolio
| TPortfolio
| GuaranteeProposal



export type timeShare =
| "1h"
| "2h"
| "3h"
| "8h"
| "12h"
| "1g"
| "3g"
| "1w"
| "2w";


export interface objResponsePayload {
    objType:string;
    value:objResponsePayloadValue;
}

export interface objResponsePayloadValue {
    Ptf: Portfolio;
    Tptf: objResponsePayloadTptf;
    Protection: GuaranteeProposal;
}

export interface objResponsePayloadTptf {
    tPortfolio:TPortfolio;
}

export interface objResponse {
    payload: objResponsePayload;
    code:number;
    error:string;
    hasError:boolean;
}

export interface codeResponse {
    payload: codeResponsePayload;
    code:number;
    error:string;
    hasError:boolean;
}
export interface codeResponsePayload {
    code: string;
}

export interface typedSharableObject {
    type:string;
    object:sharableObject;
}