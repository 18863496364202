const MAX_CONNECTION_ATTEMPTS = 10;
const EXPONENTIAL_BACKOFF_FACTOR = 1.5;
const EXPONENTIAL_BACKOFF_DIVIDER = 10;

export class ReconnectionManager {
  private maxConnectionAttempts: number;
  private connectionAttempts: number;

  constructor(maxConnectionAttempts: number = MAX_CONNECTION_ATTEMPTS) {
    this.maxConnectionAttempts = maxConnectionAttempts;
    this.connectionAttempts = 0;
  }

  public reset() {
    this.connectionAttempts = 0;
  }

  public getExponentialBackoffTime() {
    return 1000 * (EXPONENTIAL_BACKOFF_FACTOR ** this.connectionAttempts / EXPONENTIAL_BACKOFF_DIVIDER);
  }

  public tryToReconnect() {
    if (this.connectionAttempts < this.maxConnectionAttempts) {
      this.connectionAttempts++;
      console.log(`[ReconnectionManager] Next retry #${this.connectionAttempts} will be ${this.getExponentialBackoffTime()}...`)
      return true;
    }
    console.log('[ReconnectionManager] Failed to reconnect, now giving up...')
    return false;
  }

}