import { config } from "config";
import { JsonResponseError, store, Sum } from "contorller";
import { authController } from "contorller/auth/controller";
import { maxNumber, minNumber } from "contorller/utils";
import { BaseController, Puppet } from "../controller";
import { SimulatorRequest, SimulatorResponse } from "../simulator/handler";
import { ControllerSimulation } from "../types";
import { PICDActions } from "./redux";

export type PICDRequest = SimulatorRequest;
export type PICDResponse = SimulatorResponse;
export interface PICDController
  extends ControllerSimulation<PICDRequest, PICDResponse> {}

export const newControllerPICD = () => concretePICD.getInstance();

class puppetPICD implements Puppet<PICDRequest, PICDResponse> {
  callbackReady(): void {
    store.dispatch(PICDActions.websocketSetReady());
  }
  callbackNotReady(): void {
    store.dispatch(PICDActions.websocketSetNotReady());
  }
  async getUrl(): Promise<string> {
    const token = await authController().getAuther().getToken();
    return config().WS + "/picd/ws?token=" + token;
  }
  private request?: PICDRequest;
  startRolling(request: PICDRequest): void {
    store.dispatch(PICDActions.start());
    this.request = request;
  }

  private endSimulation(simulations: PICDResponse[]) {
    let request = this.request;
    if (request != null) {
      let absolutes = simulations.map<number>((e) => e.absolute);
      let annuals = simulations
        .map<number>((e) => e.annual)
        .filter((f) => !isNaN(f));
      store.dispatch(
        PICDActions.end({
          years: request.durationInvestment / 12,
          absolute: {
            min: minNumber(absolutes),
            max: maxNumber(absolutes),
            avg: Sum(absolutes) / absolutes.length,
          },
          annual: {
            min: minNumber(annuals),
            max: maxNumber(annuals),
            avg: Sum(annuals) / annuals.length,
          },
        })
      );
    }
  }

  async startRollingFallback(request: PICDRequest): Promise<any> {
    this.request = request;
    store.dispatch(PICDActions.start());

    const response = await fetch(config().URL + "/picd/", {
      headers: await authController().getAuther().getHeaderToken(),
      method: "POST",
      body: JSON.stringify(request),
    });

    if (response.status === 200) {
      const { simulations } = await response.json();
      const sims = simulations.map((d: any) => ({
        ...d,
        request,
      }));
      this.endSimulation(sims);
      return sims;
    } else {
      return [];
    }
  }

  async recvRolling(
    result: PICDResponse[],
    all: PICDResponse[]
  ): Promise<void> {
    result.forEach((e) => {
      if (this.request != null) {
        e.request = this.request;
      }
      store.dispatch(
        PICDActions.update({ loaded: all.length - 1, total: e.total })
      );
      // If we're done
      if (all.length === e.total) {
        this.endSimulation(all);
      }
    });
  }
  error(error: JsonResponseError): void {
    store.dispatch(PICDActions.setError(error));
  }
  clear(): void {
    store.dispatch(PICDActions.reset());
  }
}

class concretePICD extends BaseController<PICDRequest, PICDResponse> {
  private static _instance?: PICDController;

  private constructor() {
    super("picd", new puppetPICD());
  }
  static getInstance(): PICDController {
    if (!concretePICD._instance) {
      concretePICD._instance = new concretePICD();
    }
    return concretePICD._instance;
  }
}
