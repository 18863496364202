import { PaymentIntentResult, SetupIntentResult } from "@stripe/stripe-js";
import { isGermanUserCode } from "components/atoms/mes";
import { getMatomoInstance } from "components/context/MatomoContext";
import { Feature, config, firebaseApp, isFeatureEnabled } from "config";
import { JsonResponseError, RootState, store } from "contorller";
import { ReconnectionManager } from "contorller/simulations/utils/reconnectionManager";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { isGuaranteeUser } from "utils/guarantee";
import { AuthActions, CouponState, Product } from "./reducer";
import {
  AuthController,
  Auther,
  PaymentResponse,
  PromoCode,
  RequestPaymentParams,
  TokenHeader,
  User,
  signResponse,
  signUpRequest
} from "./types";

export const authController = (): AuthController => {
  return ConcreteAuthController.instance();
};

interface WSAuthResponse {
  validSession: boolean;
  token: string;
}

// Initialize Firebase
const app = firebaseApp();

class ConcreteAuthController implements AuthController {
  private user: User | null;
  private socket?: WebSocket;
  private manager: ReconnectionManager;
  private wsClosedByServer: boolean = false;
  private firstMessageReceived: boolean = false;
  private constructor() {
    let data = localStorage.getItem("user");
    if (data === null) {
      this.user = null;
    } else {
      let result: User = JSON.parse(data);
      this.user = result;
      store.dispatch(AuthActions.signin({ user: result }));
    }
    this.manager = new ReconnectionManager();
  }
  async refreshToken(): Promise<void> {
    const response = await fetch(config().URL + "/users/token", {
      method: "GET",
      headers: await this.getAuther().getHeaderToken(),
    });

    if (response.status === 200) {
      const data: signResponse = await response.json();

      // If logged in as normal user on pre-production, redirect to production
      // We have to include here only the user enabled to preview.
      const isGuaranteeUsers = isGuaranteeUser(data.user);
      const canBrowserPreview = isGuaranteeUsers || data.user.admin;
      if (
        window.location.origin.includes("pr.empirich.com") &&
        !canBrowserPreview
      ) {
        window.location.href = "https://v2.empirich.com";
      }

      await app.auth().signInWithCustomToken(data.token);
      console.log("refresh token ", data.token, data.user);
      store.dispatch(AuthActions.signin({ user: data.user }));
    } else {
      const data: JsonResponseError = await response.json();
      console.error(data);
      throw new Error("Errore in token refresh");
    }
  }

  private static insta: ConcreteAuthController;
  static instance(): ConcreteAuthController {
    if (!ConcreteAuthController.insta) {
      ConcreteAuthController.insta = new ConcreteAuthController();
      ConcreteAuthController.insta.setup();
    }
    return ConcreteAuthController.insta;
  }

  async requestListCodesProducts(): Promise<void> {
    let headers: TokenHeader | undefined = undefined;
    try {
      headers = await this.getAuther().getHeaderToken();
    } catch (err) {
      console.info(err);
    }
    console.log(
      headers != null ? "Retrieving user products..." : "Retrieving products..."
    );
    const endpoint =
      headers != null ? "/users/user_products" : "/users/products";
    const response = await fetch(config().URL + endpoint, {
      headers,
    });
    if (response.status === 200) {
      const data: Product[] = await response.json();
      store.dispatch(AuthActions.listProducts({ products: data }));
    } else {
      const err: JsonResponseError = await response.json();
      console.error(err.code, err.error);
    }
  }

  cancelPromoCode(): void {
    store.dispatch(AuthActions.resetCouponCode());
  }

  async getPromoCode(coupon: string): Promise<PromoCode | null> {
    store.dispatch(AuthActions.getCouponCode());

    // fetch request sub
    const response = await fetch(config().URL + "/users/pay/coupon/" + coupon, {
      method: "GET",
    });
    if (response.status === 200) {
      const c: PromoCode = await response.json();
      const valid = c?.active ?? false;
      store.dispatch(
        AuthActions.doneCouponCode({
          code: valid ? c : null,
          state: valid ? CouponState.APPLIED : CouponState.NOT_FOUND,
        })
      );
      return c;
    } else {
      try {
        const data: JsonResponseError = await response.json();
        console.error(data.code, data.error);
      } catch {
        console.error(response);
      }
      store.dispatch(
        AuthActions.doneCouponCode({
          code: null,
          state: CouponState.NOT_FOUND,
        })
      );
      return null;
    }
  }

  async changePlan(
    params: RequestPaymentParams
  ): Promise<PaymentResponse | null> {
    store.dispatch(AuthActions.resetPaymentError());
    // fetch request sub
    const response = await fetch(config().URL + "/users/pay/change", {
      body: JSON.stringify({
        productId: params.productId,
        promoCode: params.promoCode,
        coupon: params.coupon,
        priceId: params.priceId,
      }),
      headers: await this.getAuther().getHeaderToken(),
      method: "POST",
    });

    if (response.status === 200) {
      const data: PaymentResponse = await response.json();
      return data;
    } else if (response.status === 201) {
      return {
        alreadyActive: true,
      } as any;
    } else {
      const data: JsonResponseError = await response.json();
      console.error(data.code, data.error);
      return {
        ...data,
      } as any;
    }

    return null;
  }

  async requestPayment(
    params: RequestPaymentParams
  ): Promise<PaymentResponse | null> {
    if (params.error) {
      store.dispatch(
        AuthActions.errorPayment({
          code: "card-error-0001",
          error: params.error.message || "Sconosciuto",
          status: "error",
        })
      );
    } else {
      store.dispatch(AuthActions.resetPaymentError());

      // fetch request sub
      const response = await fetch(config().URL + "/users/pay", {
        body: JSON.stringify({
          productId: params.productId,
          promoCode: params.promoCode,
          coupon: params.coupon,
          priceId: params.priceId,
        }),
        headers: await this.getAuther().getHeaderToken(),
        method: "POST",
      });
      console.log("Got response", response);
      if (response.status === 200) {
        const data: PaymentResponse = await response.json();
        const signIn = data.response;
        await app.auth().signInWithCustomToken(signIn.token);
        store.dispatch(AuthActions.signin({ user: signIn.user }));
        return data;
      } else if (response.status === 201) {
        return {
          alreadyActive: true,
        } as any;
      } else {
        const data: JsonResponseError = await response.json();
        console.error(data.code, data.error);
      }
    }

    return null;
  }

  async requestForgotPassword(email: string) {
    // fetch request sub
    return fetch(config().URL + "/users/mark", {
      body: JSON.stringify({
        email,
      }),
      method: "POST",
    }).then((res) => res.json());
  }
  async completeForgotPassword(mark: string, password: string) {
    // fetch request sub
    return fetch(config().URL + "/users/password/" + mark, {
      body: JSON.stringify({
        password,
      }),

      method: "POST",
    }).then((res) => res.json());
  }

  async completePayment(
    result: PaymentIntentResult | SetupIntentResult
  ): Promise<void> {
    const { error } = result;
    if (error != null) {
      store.dispatch(
        AuthActions.errorPayment({
          code: error.code ?? "#stripe-001",
          error: error.message ?? "Qualcosa è andato stort",
          status: "500",
        })
      );
    }

    if (
      "paymentIntent" in result &&
      result.paymentIntent?.status === "succeeded"
    ) {
      store.dispatch(AuthActions.enableUser());
    }
    if ("setupIntent" in result && result.setupIntent?.status === "succeeded") {
      store.dispatch(AuthActions.enableUser());
    }
  }

  public async refreshInfo() {
    const token = await this.getAuther().getToken();
    await this.getInfo(token);
  }

  async getInfo(token: string): Promise<void> {
    console.log("[get user info]");
    console.log(config());

    const response = await fetch(config().URL + "/users/", {
      headers: { Authorization: "firebase " + token },
      method: "GET",
    });
    if (response.status === 200) {
      const data: signResponse = await response.json();
      this.user = data.user;
      localStorage.setItem("user", JSON.stringify(this.user));
      store.dispatch(AuthActions.signin({ user: data.user }));
      if (
        data.user.deadlinePeriod != null &&
        data.session.subscription !== data.user.deadlinePeriod
      ) {
        console.log(`
                session: ${new Date(data.session.subscription * 1000)}
                user:    ${new Date(data.user.deadlinePeriod * 1000)}
                >>Non c'è consistenza nella sottoscrizione viene avviato un refresh token
                `);
        this.refreshToken();
      }
    } else {
      const data: JsonResponseError = await response.json();
      this.user = null;
      //localStorage.removeItem("user");
      this.wsClosedByServer = true;
      await this.signOut(this.wsClosedByServer);
      store.dispatch(AuthActions.error({ error: data, user: undefined }));
    }
  }

  private async setup(): Promise<void> {
    store.dispatch(AuthActions.isFething());
    app.auth().onAuthStateChanged(async (u) => {
      if (u != null) {
        store.dispatch(AuthActions.isFething());
        const token = await u.getIdToken();
        console.log("token", token);
        await this.getInfo(token);
        this.setWebsocket();
      } else {
        localStorage.removeItem("user");
        store.dispatch(AuthActions.signout(this.wsClosedByServer));
        this.socket?.close();
      }
      store.dispatch(AuthActions.load());
    });
  }

  isOnline(): boolean {
    return this.user === null ? false : true;
  }

  async signInWithCode(code: string): Promise<void> {
    const response = await fetch(config().URL + "/users/legacy/signin/" + code);
    if (response.status === 200) {
      const data: signResponse = await response.json();
      store.dispatch(AuthActions.isFething());
      localStorage.setItem("user", JSON.stringify(data.user));
      try {
        await app.auth().signInWithCustomToken(data.token);
      } catch (e) {
        console.error("FirebaseError: ", e);
        store.dispatch(
          AuthActions.error({
            error: { error: "Errore imprevisto nel login. " },
          })
        );
        return;
      }
      store.dispatch(AuthActions.signin({ user: data.user }));
    } else {
      const data: JsonResponseError = await response.json();
      localStorage.removeItem("user");
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async signIn(email: string, password: string): Promise<void> {
    store.dispatch(AuthActions.isFething());

    const response = await fetch(config().URL + "/users/signin", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    if (response.status === 200) {
      const data: signResponse = await response.json();
      store.dispatch(AuthActions.isFething());
      localStorage.setItem("user", JSON.stringify(data.user));
      try {
        await app.auth().signInWithCustomToken(data.token);
      } catch (e) {
        console.error("FirebaseError: ", e);
        store.dispatch(
          AuthActions.error({
            error: { error: "Errore imprevisto nel login. " },
          })
        );
        return;
      }
      getMatomoInstance()?.trackEvent("Auth", "Login", email);
      store.dispatch(AuthActions.signin({ user: data.user }));
    } else {
      const data: JsonResponseError = await response.json();
      localStorage.removeItem("user");
      getMatomoInstance()?.trackEvent("Auth", "LoginFailed", email);
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async signUp(request: signUpRequest): Promise<void> {
    store.dispatch({ type: "auth/fetch" });

    if (request.address.nation !== "Italia") {
      request.fiscalCode = isEmpty(request.fiscalCode)
        ? "0000000000000000"
        : request.fiscalCode;
      request.vatNumber = isEmpty(request.vatNumber)
        ? "000000000"
        : request.vatNumber;
      request.sdi = isEmpty(request.sdi) ? "9999999" : request.sdi;
      request.pec = isEmpty(request.pec) ? "no@pec.it" : request.pec;
    }

    const response = await fetch(config().URL + "/users/signup", {
      method: "POST",
      body: JSON.stringify(request),
    });

    if (response.status === 200) {
      const data: signResponse = await response.json();
      store.dispatch(AuthActions.isFething());
      localStorage.setItem("user", JSON.stringify(data.user));
      await app.auth().signInWithCustomToken(data.token);
      store.dispatch(AuthActions.signin({ user: data.user }));
    } else {
      const data: JsonResponseError = await response.json();
      localStorage.removeItem("user");
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async signOut(forced: boolean): Promise<void> {
    const email = this.user?.email;
    await app.auth().signOut();
    this.user = null;
    localStorage.removeItem("user");
    this.wsClosedByServer = true;
    getMatomoInstance()?.trackEvent("Auth", "Logout", email);

    // In case of the first message received we disable the forced logout.
    store.dispatch(
      AuthActions.signout(!this.firstMessageReceived ? false : forced)
    );
    this.socket?.close();
  }

  async update(user: User): Promise<void> {
    store.dispatch(AuthActions.isFething());
    const response = await fetch(config().URL + "/users/update", {
      method: "POST",
      body: JSON.stringify(user),
      headers: await this.getAuther().getHeaderToken(),
    });
    if (response.status === 200) {
      const data: User = await response.json();
      store.dispatch({ type: "auth/update", user: data });
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async revoke(): Promise<void> {
    const response = await fetch(config().URL + "/users/revoke", {
      method: "GET",
      headers: await this.getAuther().getHeaderToken(),
    });

    if (response.status === 200) {
      const token = await this.getAuther().getToken();
      console.log("rivocato", await response.json());
      await this.getInfo(token);
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async hook(): Promise<void> {
    if (this.user === null) throw new Error("Devi essere online");
    if (this.user === null) throw new Error("Devi essere online");
    const request = {
      sourceId: this.user.uid,
      subscriptionType: "month",
    };
    const response = await fetch(config().URL + "/users/hook", {
      method: "POST",
      body: JSON.stringify(request),
    });

    if (response.status === 200) {
      await this.refreshToken();
    } else {
      const data: JsonResponseError = await response.json();
      console.error(data, request);
      //   throw new Error(JSON.stringify(data))
    }
  }

  getUser(): User | undefined {
    return store.getState().auth.user;
  }

  private async getToken(refresh?: boolean): Promise<string> {
    const t = await app.auth().currentUser?.getIdToken(refresh);
    if (t) {
      return t;
    }
    console.log("[try token fb]", t);

    throw new Error("Non sei loggato");
  }
  getAuther(): Auther {
    return {
      getToken: async () => await this.getToken(),
      getHeaderToken: async () => {
        return { Authorization: "firebase " + (await this.getToken()) };
      },
      isAdmin: () => this.user?.admin || false,
      isGermanUser: () => isGermanUserCode(this.user?.userCode),
    };
  }

  private async setWebsocket(): Promise<void> {
    this.firstMessageReceived = false;
    this.socket = new WebSocket(await this.getUrl());
    this.socket.addEventListener("open", (e) => this.open(e));
    this.socket.addEventListener("close", (e) => this.close(e));
    this.socket.addEventListener("error", (e) => this.error(e));
    this.socket.addEventListener("message", (e) => this.message(e));
  }

  async getUrl(): Promise<string> {
    const token = await authController().getAuther().getToken();
    return config().WS + "/users/ws?token=" + token;
  }

  private open(e: Event) {
    console.log("[AUTH WS] - Connessione stabilita con servizio");
  }
  private close(e: Event) {
    if (!this.wsClosedByServer) {
      console.log(
        "[AUTH WS] - Chiusa connessione con servizio da client, ritenta in 2 sec."
      );
      this.reconnect(3);
    } else {
      console.log(
        "[AUTH WS] - Chiusa connessione con servizio da server, rilevato accesso contamporaneo, o user signOut"
      );
    }
  }
  private error(e: Event) {
    console.error("[AUTH WS] - Errore con servizio: ", e);
    this.wsClosedByServer = true;
  }

  async message(e: MessageEvent<string | Blob>) {
    const tracker = getMatomoInstance();

    // If not enabled, just ignore the message.
    if (!isFeatureEnabled(Feature.SINGLE_SESSION)) {
      return;
    }

    try {
      if (typeof e.data !== "string") {
        console.error(
          "[AUTH WS] - È stato utilizzato un tipo incorretto per il messaggio: ",
          e.data != null && typeof e.data
        );
      } else {
        let data = JSON.parse(e.data) as WSAuthResponse;
        // Invalid session
        if (!data.validSession) {
          this.wsClosedByServer = true;

          tracker?.trackEvent("Auth", "MultipleSessionFound");
          console.log("[AUTH WS] - Sign out obbligato dal server");
          this.signOut(this.wsClosedByServer);
        } else {
          this.wsClosedByServer = false;
          // Token reset
          if (data.token !== "") {
            await app.auth().signInWithCustomToken(data.token);
            console.log(
              "[AUTH WS] - Logging with token: ",
              data.token,
              this.user
            );
          } else {
            console.log("[AUTH WS] - Ping del server, tutto regolare");
          }

          // First message is now received.
          this.firstMessageReceived = true;
        }
      }
    } catch (e) {
      console.error("[AUTH WS] - Errore: ", e);
    }
  }
  //TODO: da controllare se funziona correttamente questa funzione.
  private reconnect(seconds: number) {
    if (!this.isOnline && this.manager.tryToReconnect()) {
      const timeout = this.manager.getExponentialBackoffTime();
      setTimeout(() => {
        this.setWebsocket().catch((e) => {
          this.reconnect(seconds);
        });
      }, timeout);
    }
    // if (!this.isOnline) {
    //   setTimeout(() => {
    //     this.setWebsocket().catch((e) => {
    //       this.reconnect(seconds * seconds);
    //     });
    //   }, seconds * 1000);
    // }
  }
}

export function useBetaUser() {
  return useSelector<RootState, boolean>(
    (state) => state.auth.user?.betaTester ?? false
  );
}

export function useIsLoggedIn() {
  return useSelector<RootState, boolean>(
    (state) => state.auth.appIsLoad && state.auth.isLogged
  );
}
