import {
	QueryKey,
	UseQueryOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useState } from "react";
import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts";

import { config } from "config";
import { Dictionary, first, isEmpty, isString } from "lodash";
import Papa from "papaparse";
import { read, utils } from "xlsx";
import { authController } from "./auth/controller";
import { runRawEmpirichQuery } from "./endpoint";
import { Fund } from "./fund/redux";
import med_to_isin from "./med_to_isin";
import { PortfolioFund } from "./portfolio/types";
import { myFormatDate } from "./simulations/PIC/fx";
import {
	FundWeight,
	Investment,
	PACResponse,
	PICResponse,
	Snap,
} from "./simulations/types";

const translateDictionary: Dictionary<string> = med_to_isin.reduce(
	(prev, next) => ({
		...prev,
		[next.PAC]: next.ISIN,
		[next.PIC]: next.ISIN,
	}),
	{},
);

export async function readXlsFile(file: File): Promise<string[][]> {
	const wb = read(await file.arrayBuffer(), {
		cellDates: true,
	});
	if (wb.SheetNames.length > 0) {
		const vals: string[][] = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
			header: 1,
		}) as any;
		return vals.map((d) => d.map((r) => (isString(r) ? r.trimEnd() : r)));
	}
	return [];
}

export function convertPortfolioFundToFundWeight(f: PortfolioFund): FundWeight {
	return {
		end: f.fund.history.end,
		start:
			f.fund.history.proxy && f.proxed
				? f.fund.history.proxy
				: f.fund.history.start,
		isin: f.fund.isin,
		fund: f.fund,
		id: f.id,
		name: f.fund.name,
		weight: f.percentage,
	};
}

export function convertPortfolioToFundWeight(
	funds: PortfolioFund[],
): FundWeight[] {
	return funds.map<FundWeight>((f) => convertPortfolioFundToFundWeight(f));
}

export function convertFundToFundWeight(fund: Fund): FundWeight {
	// TO FIX.
	const history = fund.history;

	return {
		isin: fund.isin,
		id: fund.id,
		name: fund.name,
		fund: fund,
		start: history.proxy ? history.proxy : history.start,
		end: history.end,
		weight: 0,
	};
}

export function maxNumber(nums: number[]): number {
	if (nums.length === 0) return 0; // throw new Error("devi passarmi un array di numeri con almeno un elemento")
	const result = nums.sort((p, q) => q - p);
	return result[0];
}

export function minNumber(nums: number[]): number {
	if (nums.length === 0) return 0; // throw new Error("devi passarmi un array di numeri con almeno un elemento")
	const result = nums.sort((p, q) => p - q);
	return result[0];
}

export function convertFundToPortfolioFund(
	fund: Fund,
	shouldEnableProxy: boolean = true,
): PortfolioFund {
	return {
		fund,
		id: fund.id,
		quote: 0,
		money: fund.amount || 0,
		percentage: 0,
		proxed:
			fund.history.proxy !== undefined &&
			fund.history.proxy !== 0 &&
			shouldEnableProxy
				? true
				: false,
	};
}

export function isISIN(value: string): boolean {
	if (!value) return false;
	return importIsinString(value).length === 1;
}

export function importTickerString(isins: string): string[] {
	//FR0000133308
	const result: string[] = [];
	for (let i of isins.matchAll(/[a-zA-Z-0-9_$€£]{5,9}/g)) {
		//console.log(i)
		result.push(i[0]);
	}
	return result;
}

export function translateMedCodeToIsin(value: string): string {
	if (value != null && value.length === 3 && value in translateDictionary) {
		return translateDictionary[value];
	} else {
		return value;
	}
}
export function translateMedCodeToIsins(value: string[]): string[] {
	return value.map((d) => {
		if (d in translateDictionary) {
			return translateDictionary[d];
		} else {
			return d;
		}
	});
}

export function importIsinString(isins: string): string[] {
	//FR0000133308
	// console.log('Match', isins)
	const result: string[] = [];
	for (let i of isins.matchAll(/[A-Z]{2}[A-Z0-9]{9}[0-9]{1}/g)) {
		const isin = i[0].trimEnd();
		if (isin.length <= 12) {
			result.push(isin);
		}
	}
	return result;
}

function parseAmount(amount: any): any {
	if (typeof amount === "number") {
		return amount;
	}
	// First let's remove any currency sign.
	return parseFloat(
		(amount || "0")
			.replace(/$|€|£|¥/gi, "")
			.replace(/\./g, "")
			.replace(/,/g, ".")
			.trimEnd(),
	);
}

export interface ParsedFund {
	isin: string;
	amount: number;
	quote: number;
}
export function parseMediolanumInput(value: any): ParsedFund[] {
	// First trim the start and the end to avoid lines misleading.
	const input: any = fixMediolanumFormat(value);
	const result: any = Papa.parse(input, {
		delimiter: "\t",
		header: true,
	});
	console.log(result);

	// Get the two isin and amount columns.
	const isinColumn: any = result.meta.fields.find(
		(c: any) => c.indexOf("ISIN") >= 0,
	);
	const amountColumn: any = result.meta.fields.find(
		(c: any) => c.indexOf("CONTROVALORE") >= 0,
	);

	const quoteColumn: any = result.meta.fields.find(
		(c: any) => c.indexOf("SALDO QUOTE") >= 0,
	);
	// Safe check.
	if (isinColumn < 0 || amountColumn < 0) {
		throw new Error("Cannot find isin or amount colums");
	}
	// Let's get all the values we need.
	const parsedResults = result.data.map((f: any) => ({
		isin: translateMedCodeToIsin(f[isinColumn]),
		amount: parseAmount(f[amountColumn]),
		quote: parseAmount(f[quoteColumn]),
	}));

	// Now we have to check if the fund is available.
	return (parsedResults as ParsedFund[]).filter((e) => isISIN(e.isin));
}

export function parseFinecoInput(input: any): ParsedFund[] {
	const text = input
		.replace("Peso Fondi/Sicav\n/ETF/ETC", "Peso Fondi/Sicav/ETF/ETC")
		.replace("Ranking\nAdvice", "Ranking Advice");
	console.log(input);
	console.log(text);
	const result: any = Papa.parse(text, {
		delimiter: "\t",
		header: true,
	});

	// Get the two isin and amount columns.
	const isinColumn: any = result.meta.fields.find(
		(c: any) => c.toLowerCase().indexOf("isin") >= 0,
	);
	const amountColumn: any = result.meta.fields.find(
		(c: any) => c.toLowerCase().indexOf("controvalore") >= 0,
	);
	// Let's get all the values we need.
	const parsedResults = result.data.map((f: any) => ({
		isin: f[isinColumn],
		amount: parseAmount(f[amountColumn]),
	}));

	// Now we have to check if the fund is available.
	return (parsedResults as ParsedFund[]).filter((e) => isISIN(e.isin));
}

export function parseBNLInput(input: any): ParsedFund[] {
	const text = input;
	console.log(input);
	console.log(text);
	const result: any = Papa.parse(text, {
		delimiter: "\t",
	});
	console.log(result);

	return [];
}

export function fixMediolanumFormat(input: any) {
	return input
		.split(/\r?\n/)
		.filter((p: any) => p.indexOf(" ▼") < 0)
		.reduce((prev: any, next: any, index: any, arr: any) => {
			// Only on 0 or odd indexes.
			if (index % 2 === 0) {
				return [...prev, next + "\t" + arr[index + 1]];
			} else {
				return prev;
			}
		}, [])
		.map(function (l: any) {
			return l.trimStart();
		})
		.join("\n")
		.trimStart()
		.trimEnd()
		.replaceAll(/Totale(.)*\n(.)*\n(.)*MWRR/g, "")
		.split(/\r?\n/)
		.map((d: string) => {
			const cols = d.split(/\t/);
			const firstCol = first(cols) ?? "";
			if (firstCol.match(/([A-Z]{2})([A-Z0-9]{9})([0-9]{1})/g)) {
				return ` \t${d}`;
			} else {
				return d;
			}
		})
		.join("\n");
	// let processedLine: string[] = [];
	// let lookAhead: string = "";
	// let firstLine = false;
	// for (let line of lines) {
	//   if (line.startsWith("\t")) {
	//     lookAhead = line.trimStart() + "\t";
	//     continue;
	//   }
	//   let val = lookAhead + line;
	//   const columns = val.split(/\t/);

	//   if (!firstLine && columns.length > 13) {
	//     lookAhead = last(columns) ?? "";
	//     val = lookAhead + columns.slice(0, -2).join("\t");
	//   } else {
	//     lookAhead = "";
	//   }

	//   console.log(val, columns.length);
	//   processedLine.push(val);

	//   firstLine = true;
	// }
	// console.log(processedLine);
	// return processedLine.join("\n");
	// // .replaceAll(/[\t|\n][\w -]*\n/g, "\n\t");
}

export function importFromExcelFile(rows: string | number[][]): ParsedFund[] {
	const result: ParsedFund[] = [];
	let isQuote = false;
	let isBanco = false;
	for (let row of rows) {
		console.log(row[0], row[1]);
		if (!row[0] || !row[1]) continue;
		const isin = translateMedCodeToIsin(row[0].toString().toUpperCase());
		const amount = row[1].toString().toUpperCase().replace(",", ".");
		if (isBanco) {
			result.push({
				isin: row[2].toString().toUpperCase(),
				amount: parseFloat(row[12].toString().toUpperCase()),
				quote: parseFloat(row[6].toString().toUpperCase()),
			});
		}
		if (isin === "HORIZONTE TEMPORAL") {
			isBanco = true;
		}
		if (amount === "QUOTE") {
			console.log("Is quote!");
			isQuote = true;
		}
		if (isISIN(isin)) {
			result.push({
				isin,
				amount: isQuote ? 0 : parseFloat(amount) || 0,
				quote: isQuote ? parseFloat(amount) || 0 : 0,
			});
		}
	}
	console.log("import excel result", result);
	return result;
}

export function getDays(start: Date, end: Date): number {
	if (start.getTime() > end.getTime())
		throw new Error("non puoi passarmi lo start superiore all'end");

	let hours = Math.abs((start.getTime() - end.getTime()) / 1000 / 60 / 60);
	let days = Math.ceil(hours / 24);
	// console.log("Get days", start.toISOString(), end.toISOString(), days);

	return days;
}

export function unixToDate(seconds: number): Date {
	return new Date(seconds * 1000);
}

export function dateToUnix(date: Date): number {
	return parseInt((date.getTime() / 1000).toFixed());
}

export function picToPac(pic: PICResponse): PACResponse {
	const i = pic.snaps.map<Investment>((e) => {
		return {
			unix: e.unix,
			money: 0,
		};
	});

	return {
		index: 0,
		total: 0,
		simulationsWithSpots: [],
		annual: 0,
		absolute: 0,
		request: {
			...pic.request,
			monthFrequency: 1,
			initialInvestment: pic.request.initialInvestment,
			funds: pic.request.funds,
			rate: 0,
			differimentMounth: 0,
			days: [1],
			durationInvestment: 1,
			start: pic.snaps[0].unix,
			end: pic.snaps[pic.snaps.length - 1].unix,
		},
		snaps: pic.snaps,
	};
}

export function cutSnaps(snaps: Snap[], start?: Date, end?: Date): Snap[] {
	let startIndex = 0;
	let endIndex = snaps.length;

	if (start || end) {
		let story = snaps.map<string>((e) => myFormatDate(new Date(e.unix * 1000)));

		if (start) {
			startIndex = story.indexOf(myFormatDate(start));
			if (startIndex === -1) {
				throw new Error("non trovo lo start che mi hai indicato.");
			}
		}
		if (end) {
			endIndex = story.indexOf(myFormatDate(end));
			if (endIndex === -1) {
				throw new Error("non trovo end che mi hai indicato.");
			}
		}
		if (startIndex > endIndex) {
			throw new Error(
				"stai provando a tagliare la storia indicando la fine del taglio in data precedente all'inizio del taglio.",
			);
		}
	}

	return snaps.slice(startIndex, endIndex + 1);
}

export function getPercentage(start: number, end: number): number {
	return parseFloat(((start / end) * 100 - 100).toFixed(3));
}

export function annualEarge(ctu: number, cti: number, days: number): number {
	let a = ctu / cti;
	let y = days / 365;
	let g = 1 / (y === 0 ? 1 : y);
	if (isNaN(a)) a = 0;
	return Math.pow(a, g) - 1;
}

export function yearlyYield(
	amount: number,
	investedAmount: number,
	numberOfDays: number,
): number {
	return annualEarge(amount, investedAmount, numberOfDays);
}

export function absoluteEarge(ctu: number, cti: number): number {
	return ctu / cti - 1;
}

export function openJsonDownload(data: any) {
	let something = window.open(
		"data:text/json," + encodeURIComponent(JSON.stringify(data)),
		"_blank",
	);
	if (something) something.focus();
}

export function Int(n: number): number {
	return parseInt(n.toString());
}

export interface APIRequest<Vars = {}, Data = {}> {
	endpoint: APIEndpoint<Vars>;
	baseUrl?: string;
	headers?: Dictionary<string>;
	method?: "GET" | "POST" | "PUT" | "DELETE";
	dataKey?: string;
	legacy?: boolean;
	body?: Vars;
	json?: boolean;
}

type APIEndpoint<Vars> = string | ((vars: Vars) => string);

export async function runEmpirichQuery<T, Vars = {}>(
	request: APIRequest<Vars, T>,
): Promise<T> {
	let auther = authController().getAuther();
	let headers = request.headers ?? {};
	try {
		headers = Object.assign({}, headers, await auther.getHeaderToken());
	} catch {}

	return runRawEmpirichQuery({
		...request,
		baseUrl: request.baseUrl ?? config().URL,
		headers,
	});
}

export function useEmpirichQuery<T, Vars = {}>(
	request: APIRequest<Vars, T>,
	queryKey: QueryKey,
	options?: UseQueryOptions<T>,
) {
	return useQuery<T>(queryKey, async () => runEmpirichQuery(request), options);
}

export function useEmpirichMutation<T, Data = {}, Body = Data>(
	request: APIRequest<Data, Body>,
	queryKey: QueryKey,
	options?: UseQueryOptions<T> & {
		queriesToInvalidate?:
			| ((input: Data, response: T) => QueryKey[])
			| QueryKey[];
		shouldThrowError?: boolean;
	},
) {
	let auther = authController().getAuther();
	let getEndpoint = (data: Data) =>
		typeof request.endpoint === "string"
			? request.endpoint
			: request.endpoint(data);
	const queryClient = useQueryClient();
	return useMutation<T, {}, Data>(
		queryKey,
		async (data) => {
			let headers = {};
			try {
				headers = await auther.getHeaderToken();
			} catch {}
			return fetch(config().URL + getEndpoint(data), {
				headers,
				method: request.method ?? "POST",
				body:
					request.method !== "GET"
						? JSON.stringify(request.body ?? data)
						: null,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.hasError && options?.shouldThrowError) {
						throw new Error(res.error)	
					} 
					return request.dataKey != null ? res.payload[request.dataKey] : res.payload;
				},
				)
				.then((res) => {
					if (options?.queriesToInvalidate != null) {
						let queriesToInvalidate: QueryKey[] = [];
						if (typeof options.queriesToInvalidate === "function") {
							queriesToInvalidate = options.queriesToInvalidate(data, res);
						} else {
							queriesToInvalidate = options.queriesToInvalidate;
						}
						if (!isEmpty(queriesToInvalidate)) {
							console.log("Invalidate", queriesToInvalidate);
							return Promise.all(
								queriesToInvalidate.map((d) =>
									queryClient.invalidateQueries(d),
								) ?? [],
							).then(() => res);
						}
					}

					return res;
				});
		},
		options as any,
	);
}

interface WindowSize {
	width: number;
	height: number;
}

export function useWindowSize(): WindowSize {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: 0,
		height: 0,
	});

	const handleSize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEventListener("resize", handleSize);

	// Set size at the first client-side load
	useIsomorphicLayoutEffect(() => {
		handleSize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return windowSize;
}
