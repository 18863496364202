import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { EarnTable } from "../types";
import { DCRequest } from "./controller";

export interface DCState {
  websocketIsReady: boolean;
  inRunning: boolean;
  request: DCRequest;
  index: number;
  response: {
    loaded: number;
    total: number;
    selected: number;
    table?: EarnTable;
  };
  error?: JsonResponseError;
}

const initialState: DCState = {
  websocketIsReady: false,
  inRunning: false,
  index: 0,
  request: {
    start: 0,
    end: 0,
    funds: [],
    durationInvestment: 120,
    durationService: 24,
    investment: 100000,
    days: [1],
  },
  response: {
    loaded: 0,
    total: 0,
    selected: 0,
  },
};

const dcStore = createSlice({
  name: "dc",
  initialState,
  reducers: {
    websocketSetNotReady: (state) => {
      state.websocketIsReady = false;
    },
    websocketSetReady: (state) => {
      state.websocketIsReady = true;
    },
    setRequest: (state, action: PayloadAction<DCRequest>) => {
      state.request = action.payload;
    },
    update: (
      state,
      action: PayloadAction<{ loaded: number; total: number }>
    ) => {
      state.response.loaded = action.payload.loaded;
      state.response.total = action.payload.total;
    },
    setError: (state, action: PayloadAction<JsonResponseError>) => {
      state.error = action.payload;
      state.inRunning = false;
    },
    start: (state) => {
      state.inRunning = true;
      state.response.loaded = 0;
      state.response.total = 0;
      state.response.table = undefined;
    },
    setIndex: (state, action: PayloadAction<{ index: number }>) => {
      state.index = action.payload.index;
    },
    end: (state, action: PayloadAction<EarnTable>) => {
      state.inRunning = false;
      state.index = state.response.total - 1;
      state.response.table = action.payload;
    },
    reset: () => initialState,
  },
});

export const DCActions = dcStore.actions;
export const DCReducer = dcStore.reducer;
