import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { Portfolio } from "contorller/portfolio/types";

export interface FundReturnStat {
  lastCalc: number;
  returns: { months: number; start: number; end: number; return: number }[];
  infoValues: { months: number; start: number; end: number; return: number }[];
}
export interface FundSharpeStat {
  lastCalc: number;
  infoValues: { months: number; start: number; end: number; return: number }[];
}
export interface FundAnnaulizedStat {
  lastCalc: number;
  infoValues: { months: number; start: number; end: number; return: number }[];
}
export interface FundMaxDrawdownStat {
  lastCalc: number;
  Value: number;
  infoValues: {
    months: number;
    start: number;
    end: number;
    Value: number;
    return: number;
  }[];
}
export interface FundVolatilityStat {
  lastCalc: number;
  infoValues: {
    months: number;
    start: number;
    end: number;
    Value: number;
  }[];
}

export enum FundWarningType {
  WRONG_VALUE = 1,
  OUTLIER = 2,
  NOT_UPDATED = 3,
  METRICS_ERROR = 4,
}

export interface Fund extends Metrics {
  id: string;
  name: string;
  isin: string;
  startDate: string;
  currency: string;
  nameMarket: string;
  startHistory: string;
  reserved: number;
  fidaTicker: string;
  metricsQuarantine: boolean;
  society: string;
  category: string;
  superCategory: string;
  etf: number;
  dividend?: {
    lastPaymentMonths: number[];
    lastYear: number[];
    dividends: { date: string; paymentDate: string; value: number }[];
  };
  distribution: number;
  period: number;
  status: number;
  provider: string;
  history: { start: number; end: number; proxy: number };
  dataMerge: string;
  active: boolean;
  delete: boolean;
  bank?: string[];
  valueCost?: number;
  relegationValue?: number;
  amount: number;
  quote: number;
  warns: {
    text: string;
    numeric: number;
    type: FundWarningType;
  }[];
}

export interface Metrics {
  return?: FundReturnStat;
  annualizedReturn?: FundAnnaulizedStat;
  maxDrawdown?: FundMaxDrawdownStat;
  sharpe?: FundSharpeStat;
  volatility?: FundVolatilityStat;
}

export interface FundsState {
  importer: {
    isinRequestLoading: boolean;
    mediolanumImport: boolean;
    funds: Fund[];
    error?: JsonResponseError;
  };
}

const fundState = createSlice({
  name: "funds",
  initialState: {
    importer: {
      isinRequestLoading: false,
      mediolanumImport: false,
      funds: [],
    },
  } as FundsState,
  reducers: {
    startIsinLoad: (state) => {
      state.importer.isinRequestLoading = true;
    },
    endIsinLoad: (state) => {
      state.importer.isinRequestLoading = false;
    },
    clearImporter: (state) => {
      state.importer.funds = [];
    },
    deleteFundInImporter: (state, action: PayloadAction<{ id: string }>) => {
      state.importer.funds = state.importer.funds.filter(
        (e) => e.id !== action.payload.id
      );
    },
    appendInImporter: (
      state,
      action: PayloadAction<{ funds: Fund[]; shouldAppendAmount?: boolean }>
    ) => {
      (action.payload.funds || []).forEach((e) => {
        const previousIndex = state.importer.funds
          .map((i) => i.id)
          .indexOf(e.id);
        if (previousIndex >= 0) {
          if (action.payload.shouldAppendAmount) {
            state.importer.funds[previousIndex].amount += e.amount;
          }
        } else {
          state.importer.funds.push(e);
        }
      });
    },
    error: (state, action: PayloadAction<{ error: JsonResponseError }>) => {
      state.importer.error = action.payload.error;
    },
    noError: (state) => {
      state.importer.error = undefined;
    },
  },
});

export function isFund(fund: Fund | Portfolio): fund is Fund {
  return "fidaTicker" in fund;
}

export const FundsActions = fundState.actions;
export const FundsReducer = fundState.reducer;
