import { first, last } from "lodash/fp";

export function calculateVariation(
  oldValue: number,
  value: number,
  isInverse: boolean = false,
  ranges: number[] = [-0.2, -0.05, 0.05, 0.2]
) {
  const rangeValues = ranges.map((r) => {
    return oldValue * (1 + r);
  });
  const minRange = first(rangeValues) ?? 0;
  const maxRange = last(rangeValues) ?? 1;

  let limitMultiplier = 0;
  let min = minRange;
  let max = maxRange;
  let isBiggerThanLimits = false;

  do {
    limitMultiplier++;
    min = (1 + (first(ranges) ?? 0) * limitMultiplier) * oldValue;
    max = (1 + (last(ranges) ?? 1) * limitMultiplier) * oldValue;
    const isPositive = max > min;
    isBiggerThanLimits = isPositive
      ? value < min || value > max
      : value > min || value < max;
  } while (isBiggerThanLimits && limitMultiplier < 50);

  const positive = value > oldValue;

  let percentage = (value - oldValue) / oldValue;
  let positivePercentage = percentage > 0;
  percentage = positive !== positivePercentage ? percentage * -1 : percentage;
  percentage = isInverse ? -percentage : percentage;

  // console.log(oldValue, value, percentage)
  // console.log(oldValue, value, positive !== positivePercentage ? 'True' : 'False', isInverse ? 'True' : 'False', percentage)

  return {
    value: isFinite(percentage) ? percentage : 0,
    inverse: isInverse,
    min: first(ranges) as number,
    max: last(ranges) as number,
  };
}
